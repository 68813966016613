import API from "../Network/API"
import React, { useState,  useEffect } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

export default function TestimonialArea(){

  const [ratings, setRatings] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    API.get('/home-ratings').then((response) => {
      setRatings(response.data.data);
      setLoading(false);
    });

  }, []);

  if (loading) {
    return <div></div>;
  }


    return (
        <>
        <div className="testimonial-area pb-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 offset-lg-3">
              <div className="section-title text-center">
                <h2>Insights from our Learners</h2>
              </div>
            </div>
          </div>
          <div className="testimonial">
          <OwlCarousel className='owl-theme' loop margin={2}>

            {!loading && ratings?.length > 0 && ratings?.map((rating, key) => (

              <div className="item" key={key}>
                <div className="testimonial-items position-relative">
                  <div className="testimonial-header">
                    <div className="testimonial-img">
                      <img src="assets/img/user-1.png" alt="image not found" />
                    </div>
                    <div className="testimonial-title">
                      <h4>{rating.student.name}</h4>
                      <span>{rating.student.role}</span>
                    </div>
                  </div>
                  <div className="testimonial-rating mb-2">
                    <div className="testimonial-icon me-2">
                        <i className={`fas fa-star ${rating.rating>=0 && rating.rating<=5?'text-warning':'text-dark'}`} />
                        <i className={`fas fa-star ${rating.rating>=2 && rating.rating<=5?'text-warning':'text-dark'}`} />
                        <i className={`fas fa-star ${rating.rating>=3 && rating.rating<=5?'text-warning':'text-dark'}`} />
                        <i className={`fas fa-star ${rating.rating>=4 && rating.rating<=5?'text-warning':'text-dark'}`} />
                        <i className={`fas fa-star ${rating.rating>=5 && rating.rating<=5?'text-warning':'text-dark'}`} />
                    </div>
                    <span>{rating.rating}</span>
                  </div>
                  <div className="testimonial-body">
                    {/* <h3>{rating.comment}</h3> */}
                    <p>{rating.comment}</p>
                  </div>
                </div>
              </div>

             ))}
          </OwlCarousel>
          </div>
        </div>
      </div>
        </>
    )
}