import { combineReducers } from 'redux';

import authReducers from './authSlice';
import menuSlice from './menuSlice';
import categorySlice from './categorySlice';
import homeSlice from './homeSlice';
import courseSlice from './courseSlice';
import appearanceSlice from './appearanceSlice';
import enrollmentSlice from './enrollmentSlice';
import enrollmentLessonSlice from './enrollmentLessonSlice';
import myCourseSlice from './myCourseSlice';
import courseSectionSlice from './courseSectionSlice';
import chatListSlice from './chatListSlice';
import userInboxSlice from './userInboxSlice';
import teacherSlice from './teacherSlice';
import languageSlice from './languageSlice';
import countrySlice from './countrySlice';
import upcomingEnrollmentSlice from './upcomingEnrollmentSlice';
import teacherEnrollmentSlice from './tutorEnrollmentSlice';


export default combineReducers({
  
    authReducer:authReducers,
    menuReducer:menuSlice,
    categoryReducer:categorySlice,
    homeReducer:homeSlice,
    courseReducer:courseSlice,
    appearanceReducer:appearanceSlice,
    enrollmentReducer:enrollmentSlice,
    enrollmentLessonReducer:enrollmentLessonSlice,
    myCourseReducer:myCourseSlice,
    courseSectionReducer:courseSectionSlice,
    chatListReducer:chatListSlice,
    userInboxReducer:userInboxSlice,
    teacherReducer:teacherSlice,
    languageReducer:languageSlice,
    countryReducer:countrySlice,
    upcomingEnrollmentReducer:upcomingEnrollmentSlice,
    teacherEnrollmentReducer:teacherEnrollmentSlice,
    
});