import { useEffect, useState } from "react";
import API from "../Network/API";

export default function Faq() {

    const [loading, setLoading] = useState(true);
    const [faqdata, setFaqdata] = useState([]);
  
    useEffect(() => {
      API.get('/faq').then((response) => {
        setFaqdata(response.data);
        setLoading(false)
      })
  
      console.log(faqdata)
    }, []);

    return (
    <div className="faq-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8">
              <div className="faq-headinh mb-4 text-center">
                <h2 className="">Frequently Asked Questions</h2>
              </div>
              <div className="accordion accordion-flush" id="accordionFlush">

                {!loading ? (
                  faqdata?.data?.map((faq, key) => (
                    <div className="accordion-item" key={key}>
                    <h2 className="accordion-header">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#flush-collapse${key}`} aria-expanded="false" aria-controls={`flush-collapse${key}`}>
                        {faq.question}
                      </button>
                    </h2>
                    <div id={`flush-collapse${key}`} className="accordion-collapse collapse" data-bs-parent="#accordionFlush">
                      <div className="accordion-body">{faq.answer}</div>
                    </div>
                  </div>
                  ))
                ) : ('') }
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}