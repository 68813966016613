
import React from "react";

export default function BlankInbox() {
    return (<>
        
        <div className="empty-state no-selection text-center">
            <img src={'/assets/img/inbox.svg'}/>
            <h2 className="font-accent">Select a Conversation</h2>
            <small>Try
            selecting a conversation or searching for someone specific.</small>
            </div>
        
    </>)
}
