import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TeacherFilter from "../Props/TeacherFilter";
import TeacherProps from "../Props/TeacherProps";
import ReactPaginate from "react-paginate";
import { TEACHER_FETCH_START, TEACHER_FETCH_SUCCESS, TEACHER_TOTAL_PAGE } from "../Reducer/teacherSlice";
import API from "../Network/API";
import { useLocation } from "react-router-dom";

export default function TeacherList() {
  const location = useLocation();
  const isAppRoute = location.pathname.startsWith("/app");
  const select_teachers = useSelector((state) => state.teacherReducer);
  const [currentPage, setCurrentPage] = useState(0);
  const [keyword, setKeyword] = useState('');
  const dispatch = useDispatch();
  const [speak, setSpeak] = useState(0);
  const [category, setCategory] = useState(0);
  const [country, setCountry] = useState('');
  const [gender, setGender] = useState('');

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  const handleCategory=(categoryId)=>{
    setCategory(categoryId);
  }

  const handleSpeak=(langId)=>{
    setSpeak(langId);
  }

  const handleCountry=(conId)=>{
    setCountry(conId);
  }

  const handleGender=(conId)=>{
    setGender(conId);
  }

  useEffect(() => {
    const fetchCourses = () => {
      dispatch(TEACHER_FETCH_START())
      const pageQuery = currentPage > 0 ? `&page=${currentPage + 1}` : '';
      const q=keyword==null?'':keyword;
      API.get(`/v1/oauth/all_teachers?keyword=${q}${pageQuery}&country=${country}&category=${category}&speak=${speak}&gender=${gender}`)
        .then((response) => {
          dispatch(TEACHER_FETCH_SUCCESS(response.data.data));
          dispatch(TEACHER_TOTAL_PAGE(response.data.data.total_page));
        })
        .catch((error) => {

        });
    };

    fetchCourses();

  }, [currentPage,keyword,speak,category,country,gender]);



  return (
    <>
      <div className="InstructorPage">
        <div className="teacherWrapperTop">
          <div className="container">
            <div className="InstructorIntro">
              <div className="row">
                <div className="col-md-7">
                  <div className="intro-heading">
                    <h2 className="title">Find your <span>teacher</span>  online</h2>
                    <p className="mb-0 dec mt-2">
                      At xller, we offer a variety of experienced and certified  teachers and tutors to help you achieve your goals. Our online platform allows you to choose from a wide range of all off classes, courses, and lessons to suit your learning needs and schedule.
                    </p>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="banner-image d-flex justify-content-end">
                    <img className="img-fluid" src="/assets/img/teacherInter.svg" alt="shape" />
                  </div>
                </div>
              </div>
              <div className="row justify-content-between mt-4">
        <div className="d-flex justify-content-between align-items-center flex-wrap">
          <div className="dropdown dropdown-large">
            <a className="btn btn-white btn-dropdown-white rounded-pill dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              <h5 className="mb-0 d-inline">All Tutors</h5>
            </a>
           
          </div>
          <div className="top-search-area">
            <div className=" search-group">
              <i className="fa-light fa-search" />
              <input type="text" className="form-control rounded-pill form-control-lg" placeholder="Search our tutor" onKeyUp={(e)=>setKeyword(e.target.value)}/>
            </div>
          </div>
        </div>
      </div>
            </div>
          </div>
        </div>
        <div className="teacherWrapperBottom">
          <div className="container">
            <div className="showing-list">
              <TeacherFilter handleCategory={handleCategory} handleSpeak={handleSpeak} handleCountry={handleCountry} handleGender={handleGender}/>
            </div>
            <div className="InstructorList">
              <div className="row">
                <div className="col-lg-7">
                  <div className="row">
                    <div className="col-lg-12">

                      {!select_teachers?.loading && select_teachers?.teachers?.length > 0 && select_teachers?.teachers?.map((teacher, j) => (
                        <TeacherProps data={teacher}/>
                      ))}

                      <div className="mt-3 ms-3">
                        <ReactPaginate
                          pageCount={select_teachers?.total_page}
                          onPageChange={handlePageChange}
                          forcePage={currentPage}
                          containerClassName={'pagination'}
                          previousLinkClassName={'page-link'}
                          breakClassName={'page-link'}
                          nextLinkClassName={'page-link'}
                          pageClassName={'page-item'}
                          pageLinkClassName="page-link"
                          disabledClassName={'disabled'}
                          activeClassName={'active'}
                        />
                      </div>

                    </div>
                  </div>
                </div>
                <div className="col-lg-5 d-none">
                  <div className="courseDetails-SidePanelWrap">
                    <div className="courseDetails-SidePanel bg-white overflow-hidden w-100 p-0 shadow-sm">
                      <div className="courseDetails-SideMain ">
                        <div className="courseDetails-videoWrapper">
                          <div className="video-area">
                            <iframe width="100%" height="200px" src="https://www.youtube.com/embed/bKtrSaqS8Zc?si=c8lQvbO0xu6iq4CQ" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen />
                          </div>
                        </div>
                        <div className="datepicker">
                          <div className="datepicker-top">
                            <div className="month-selector">
                              <button className="arrow shadow-sm"><i className="fa-light fa-angle-left" /></button>
                              <span className="month-name">Jun 19–25, 2024</span>
                              <button className="arrow shadow-sm"><i className="fa-light fa-angle-right" /></button>
                            </div>
                          </div>
                          <div className="datepicker-calendar schdhelBar mt-3">
                            <span className="schdhelBarSingle" />
                            <span className="schdhelBarSingle" />
                            <span className="schdhelBarSingle" />
                            <span className="schdhelBarSingle schdhelBarMainColor" />
                            <span className="schdhelBarSingle" />
                            <span className="schdhelBarSingle" />
                            <span className="schdhelBarSingle" />
                          </div>
                          <div className="datepicker-calendar schdhelDay mt-2 ">
                            <span className="day">Mo</span>
                            <span className="day">Tu</span>
                            <span className="day">We</span>
                            <span className="day">Th</span>
                            <span className="day">Fr</span>
                            <span className="day">Sa</span>
                            <span className="day">Su</span>
                          </div>
                          <div className="datepicker-calendar schdhelDate">
                            <span className="day">23</span>
                            <span className="day">24</span>
                            <span className="day">25</span>
                            <span className="day">26</span>
                            <span className="day">27</span>
                            <span className="day">28</span>
                            <span className="day">29</span>
                          </div>
                          <div className="datepicker-calendar schdhelTime mt-3">
                            <button className=" btn btn-sm schdhelTime-Empty" />
                            <button className="btn btn-sm schdhelTime-text">14:30</button>
                            <button className="btn btn-sm schdhelTime-text">00:00</button>
                            <button className="btn btn-sm schdhelTime-text ">00:00</button>
                            <button className="btn btn-sm schdhelTime-Empty" />
                            <button className="btn btn-sm schdhelTime-text">15:00</button>
                            <button className="btn btn-sm schdhelTime-text">00:00</button>
                            <button className=" btn btn-sm schdhelTime-Empty" />
                            <button className="btn btn-sm schdhelTime-text active">14:30</button>
                            <button className="btn btn-sm schdhelTime-text">00:00</button>
                            <button className="btn btn-sm schdhelTime-text ">00:00</button>
                            <button className="btn btn-sm schdhelTime-Empty" />
                            <button className="btn btn-sm schdhelTime-text">15:00</button>
                            <button className="btn btn-sm schdhelTime-text">00:00</button>
                            <button className=" btn btn-sm schdhelTime-Empty" />
                            <button className="btn btn-sm schdhelTime-text">14:30</button>
                            <button className="btn btn-sm schdhelTime-text">00:00</button>
                            <button className="btn btn-sm schdhelTime-text ">00:00</button>
                            <button className="btn btn-sm schdhelTime-Empty" />
                            <button className="btn btn-sm schdhelTime-text">15:00</button>
                            <button className="btn btn-sm schdhelTime-text">00:00</button>
                            <button className=" btn btn-sm schdhelTime-Empty" />
                            <button className="btn btn-sm schdhelTime-text">14:30</button>
                            <button className="btn btn-sm schdhelTime-text">00:00</button>
                            <button className="btn btn-sm schdhelTime-text ">00:00</button>
                            <button className="btn btn-sm schdhelTime-Empty" />
                            <button className="btn btn-sm schdhelTime-text">15:00</button>
                            <button className="btn btn-sm schdhelTime-text">00:00</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}