import { createSlice } from '@reduxjs/toolkit';

export const courseSlice = createSlice({
    name: 'course',
    initialState: {
        loading: false,
        data: {},
        courses: [{id:1},{id:2},{id:3},{id:4}],
        error: false,
        total_page: 0,
    },
    reducers: {
        COURSE_FETCH_START: (state) => {
            state.loading = true
        },
        COURSE_TOTAL_PAGE: (state,action) => {
            state.total_page = action.payload
        },
        COURSE_FETCH_SUCCESS: (state, action) => {
            state.loading = false
            state.data = action.payload
            state.courses = action.payload.courses
        },
        COURSE_FETCH_ERROR: (state) => {
            state.error = true
        }
    }
});

export const { COURSE_FETCH_ERROR, COURSE_FETCH_START, COURSE_FETCH_SUCCESS,COURSE_TOTAL_PAGE } = courseSlice.actions;
export default courseSlice.reducer;