import React, { useState } from "react";
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const ScheduleSlot = ({schedule}) => {
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedEvent, setSelectedEvent] = useState(null);

    const events = schedule?.flat().map(slot => ({
        start: new Date(slot.start_time),
        end: new Date(slot.end_time),
        // title: ''
    }));
    
 
    const handleSelectEvent = (event) => {
      setSelectedEvent(event);
      console.log('Selected event:', event);
      localStorage.setItem(
        'event',
        JSON.stringify({
         start: event?.start,
          end: event.end,
        })
      )
    };

  const handleSelectSlot = ({ start, end }) => {
    setSelectedDate({ start, end });
    console.log('Selected slot:', start, end);
  };
    const localizer = momentLocalizer(moment);

    return (
        <div className="courseDetailsSchedule">
            <div className="row d-none">
                <div className="col-6">
                    <div className="checkoutHeading-payment-form-btn mb-3">
                        <ul role="tablist" className="nav bg-light nav-pills rounded nav-fill mb-3 checkoutPayment input-hight-44">
                            <li className="nav-item "> <a data-toggle="pill" href="#credit-card" className="nav-link input-hight-44 active "> <span className="d-block fw-bold pt-1">30 mins</span> </a> </li>
                            <li className="nav-item"> <a data-toggle="pill" href="#paypal" className="nav-link input-hight-44 "> <span className="d-block fw-bold pt-1">60 mins</span></a></li>
                        </ul>
                    </div>
                </div>
                <div className="col-6">
                    <div className="timezone">
                        <select className="form-select input-hight-44" aria-label="Choose timezone ...">
                            <option selected>Asia/Dhaka</option>
                            <option value={1}>One</option>
                            <option value={2}>Two</option>
                            <option value={3}>Three</option>
                        </select>
                    </div>
                </div>
        
            </div>
            <div className="datepicker">

                <Calendar
                    localizer={localizer}
                    selectable
                    onSelectSlot={handleSelectSlot}
                    onSelectEvent={handleSelectEvent}
                    events={events}
                    startAccessor="start"
                    endAccessor="end"
                    style={{ height: 500 }}
                />

            </div>
        </div>
    );
}

export default ScheduleSlot;