import { useState } from "react";
import { useSelector } from "react-redux";


import toast from "react-hot-toast";
import StudentSettingNav from "./StudentSettingNav";
import API from "../../../Network/API";

export default function StudentPasswordChange() {
    const { user, accessToken } = useSelector((state) => state.authReducer);
    const [loading, setLoad] = useState(false);
    const [errors, setErrors] = useState({});

    // Password validation function
    const validatePassword = (password, confirmPassword) => {
        const errors = {};

        // Password criteria
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
        if (!passwordRegex.test(password)) {
            errors.password = "Password must be at least 8 characters long, contain an uppercase letter, a number, and a special character.";
        }

        // Confirm password match
        if (password !== confirmPassword) {
            errors.new_password = "New password and confirm password must match.";
        }

        return errors;
    };

    // Handle onKeyUp for live validation
    const handlePasswordChange = (event) => {
        const password = event.target.value;
        const confirmPassword = document.querySelector('input[name="new_password"]').value;
        const validationErrors = validatePassword(password, confirmPassword);
        setErrors(validationErrors);
    };

    const handleConfirmPasswordChange = (event) => {
        const confirmPassword = event.target.value;
        const password = document.querySelector('input[name="password"]').value;
        const validationErrors = validatePassword(password, confirmPassword);
        setErrors(validationErrors);
    };

    // Handle form submission
    const handleSubmit = (event) => {
        event.preventDefault();
        setLoad(true);
        const currentPassword = event.target.current_password.value;
        const newPassword = event.target.password.value;
        const confirmPassword = event.target.new_password.value;

        // Final validation before submission
        const validationErrors = validatePassword(newPassword, confirmPassword);
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            setLoad(false);
            return;
        }

        const fd = new FormData();
        fd.append('current_password', currentPassword);
        fd.append('password', newPassword);
        fd.append('new_password', confirmPassword);

        API.post("/v1/oauth/change-password", fd)
            .then((response) => {
                setLoad(false);
                toast.success("Password updated successfully!");
                localStorage.clear()
                window.location.href = '/';
            })
            .catch((err) => {
                setLoad(false);
                toast.error(err.response.data.message);
            });
    };

    return (
        <>
            <div className="settings-widget card-details shadow-sm">
                <div className="settings-menu p-0">
                    <div className="profile-heading">
                        <h3>Settings</h3>
                        <p>You have full control to manage your own account settings</p>
                    </div>
                    <div className="settings-page-head">
                        <StudentSettingNav active_menu={`change-password`} />
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="checkout-form settings-wrap">
                            <div className="edit-profile-info">
                                <h5>Password change</h5>
                                <p>Edit your password</p>
                            </div>

                            <div className="form-group mb-2">
                                <label htmlFor="current_password" className="form-label">Current Password</label>
                                <input required name="current_password" type="password" className="form-control rounded input-hight-44" placeholder="" />
                            </div>

                            <div className="form-group mb-2">
                                <label htmlFor="password" className="form-label">New Password</label>
                                <input 
                                    required 
                                    name="password" 
                                    type="password" 
                                    className="form-control rounded input-hight-44" 
                                    placeholder="" 
                                    onKeyUp={handlePasswordChange} // On key up event
                                />
                                {errors.password && <p className="text-danger">{errors.password}</p>}
                            </div>

                            <div className="form-group mb-2">
                                <label htmlFor="new_password" className="form-label">Confirm Password</label>
                                <input 
                                    required 
                                    name="new_password" 
                                    type="password" 
                                    className="form-control rounded input-hight-44" 
                                    placeholder="" 
                                    onKeyUp={handleConfirmPasswordChange} // On key up event
                                />
                                {errors.new_password && <p className="text-danger">{errors.new_password}</p>}
                            </div>

                            <div className="mb-3 d-flex justify-content-end">
                                <button type="submit" className="input-hight-44 btn btn-login box-shadow-1 fw-bold">
                                    Update {loading ? (
                                        <span className="spinner-border spinner-border-sm ml-5" role="status" aria-hidden="true"></span>
                                    ) : (
                                        <span></span>
                                    )}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}
