export default function Breadcrumb(props){
    return (
        <>
          <div className="breadcrumb-list">
                <h2 className="breadcrumb-title">{props?.title}</h2>
                <nav aria-label="breadcrumb" className="page-breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                    <li className="breadcrumb-item active" aria-current="page">{props?.sub_title}</li>
                  </ol>
                </nav>
              </div>
        </>
    )
}