import { useEffect, useState } from "react";
import API from "../../../Network/API";
import { CardElement, Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import SubscribeCard from "../../../components/SubscribeCard";
import { useSelector } from "react-redux";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
export default function Subscription() {
    const [data, setData] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const { user, accessToken } = useSelector((state) => state.authReducer);

    useEffect(() => {
        setLoading(true)
        API.get(`/v1/oauth/teacher/packages`).then((response) => {
            setData(response.data.data.packages)
            setLoading(false)
        }).catch((error) => {
            setLoading(false)
        });

    }, []);


    return (
        <>
            <div className="row row-cols-1 row-cols-md-3 mb-3 text-center">
                <Elements stripe={stripePromise}>

                    {!isLoading && data?.length > 0 && data?.map((pack, j) => (
                        <div className="col">
                            <div className="card mb-4 rounded-3 shadow-sm">
                                <div className="card-header py-3">
                                    <h4 className="my-0 fw-normal">{pack.title}</h4>
                                </div>
                                <div className="card-body">
                                    <h1 className="card-title pricing-card-title">${pack.price}<small className="text-muted fw-light">/mo</small></h1>
                                    <ul className="list-unstyled mt-3 mb-4">
                                        {pack.details}
                                    </ul>
                                    {user?.is_subscribed?
                                    <p>Subscribed</p>
                                :
                                <SubscribeCard priceId={pack.stripe_plan}/>
                                
                                }

                                </div>
                            </div>
                        </div>
                    ))}

                </Elements>


            </div>
        </>
    )
}