import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

export default function MobileNav() {
    const dispatch = useDispatch();
    const navigator = useNavigate();
    const { user, accessToken } = useSelector((state) => state.authReducer);
    const { i18n } = useTranslation();
    const { t } = useTranslation();
    return (
        <>
            <div className="nav__menu" id="nav-menu">
                <ul className="nav__list list-unstyled mb-0">
                    <li className="nav__item">
                        <Link to="/app" className="nav__link">
                            <i className="fa-light fa-home nav__icon" />
                            <span className="nav__name">Home</span>
                        </Link>
                    </li>
                    <li className="nav__item">
                        <Link to={`/app/teachers`} className="nav__link">
                            <i className="fa-light fa-user nav__icon" />
                            <span className="nav__name">Find Teacher</span>
                        </Link>
                    </li>
                    <li className="nav__item">
                        <Link to={`/app/all-courses`} className="nav__link">
                            <i className="fa-light fa-book-open nav__icon" />
                            <span className="nav__name">Courses</span>
                        </Link>
                    </li>
                    {!accessToken ?
                        <li className="nav__item">
                            <Link href="/app/login" className="nav__link">
                                <i className="fa-light fa-briefcase nav__icon" />
                                <span className="nav__name">Login</span>
                            </Link>
                        </li>
                        :
                        <>
                            {accessToken && user.role == 'teacher' ?
                                <li className="nav__item">
                                    <Link to={`/app/dashboard/tutor`} className="nav__link">
                                        <i className="fa-light fa-briefcase nav__icon" />
                                        <span className="nav__name">Dashboard</span>
                                    </Link>
                                </li>
                                :
                                <li className="nav__item">
                                    <Link to={`/app/home/student`} className="nav__link">
                                        <i className="fa-light fa-briefcase nav__icon" />
                                        <span className="nav__name">Dashboard</span>
                                    </Link>
                                </li>
                            }
                        </>
                    }

                </ul>
            </div>
        </>
    )
}