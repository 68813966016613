import { createSlice } from '@reduxjs/toolkit';

export const homeSlice = createSlice({
    name: 'home',
    initialState: {
        loading: false,
        data: [],
        course: [{id:1},{id:2},{id:3},{id:4}],
        error: false,
    },
    reducers: {
        HOME_FETCH_START: (state) => {
            state.loading = true
        },
        HOME_FETCH_SUCCESS: (state, action) => {
            state.loading = false
            state.data = action.payload
        },
        HOME_FETCH_COURSE_SUCCESS: (state, action) => {
            state.loading = false
            state.course = action.payload
        },
        HOME_FETCH_ERROR: (state) => {
            state.error = true
        }
    }
});

export const { HOME_FETCH_ERROR, HOME_FETCH_START, HOME_FETCH_SUCCESS,HOME_FETCH_COURSE_SUCCESS } = homeSlice.actions;
export default homeSlice.reducer;