import { useEffect } from "react";
import API from "../Network/API";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
export default function Footer() {
  const menuItems = useSelector((state) => state.menuReducer);
  const filterByLocation = (location) => {
    return menuItems?.data?.filter(item => item.location === location);
  };

  const selector = useSelector((state) => state.appearanceReducer );
  const appearanceData = selector?.data;


  return (
    <>
      <footer>
        <div className="footer-area pt-100">
          <div className="container">
            <div className="footer-main mb-56">
              <div className="row">
                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                  <div className="footer-widget">
                    <h5 className="footer-widget-title">About US</h5>
                    <ul className="footer-widget-menu list-unstyled">
                      {filterByLocation('about').map((item) => (
                        item.type === 'url' ?
                        <li key={item.id}><a href={item?.url} target="_blank">{item.title}</a></li>
                        :
                        <li key={item.id}><Link to={`/page/${item.url}`}>{item.title}</Link></li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                  <div className="footer-widget">
                    <h5 className="footer-widget-title">For Students</h5>
                    <ul className="footer-widget-menu list-unstyled">
                    {filterByLocation('student').map((item) => (
                      item.type === 'url' ?
                      <li key={item.id}><a href={item?.url} target="_blank">{item.title}</a></li>
                      :
                      <li key={item.id}><Link to={`/page/${item.url}`}>{item.title}</Link></li>
                    ))}
                    </ul>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                  <div className="footer-widget">
                    <h5 className="footer-widget-title">For Tutors</h5>
                    <ul className="footer-widget-menu list-unstyled">
                    {filterByLocation('teacher').map((item) => (
                      item.type === 'url' ?
                      <li key={item.id}><a href={item?.url}>{item.title}</a></li>
                      :
                      <li key={item.id}><Link to={`/page/${item.url}`}>{item.title}</Link></li>
                    ))}
                    </ul>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                  <div className="footer-widget">
                    <h5 className="footer-widget-title">Xller Career</h5>
                    <ul className="footer-widget-menu list-unstyled">
                    {filterByLocation('career').map((item) => (
                      item.type === 'url' ?
                      <li key={item.id}><a href={item?.url} target="_blank">{item.title}</a></li>
                      :
                      <li key={item.id}><Link to={`/page/${item.url}`}>{item.title}</Link></li>
                    ))}
                    </ul>
                  </div>
                </div>

              </div>
            </div>
            <div className="footer-main">
              <div className="row">
                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                  <div className="footer-widget">
                    <h5 className="footer-widget-title">Contacts</h5>
                    <ul className="footer-widget-menu list-unstyled">
                      <li className="text-white"> { appearanceData.footer_contacts } </li>
                    </ul>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                  <div className="footer-widget">
                    <h5 className="footer-widget-title">Support</h5>
                    <ul className="footer-widget-menu list-unstyled">
                    {filterByLocation('support').map((item) => (
                      item.type === 'url' ?
                      <li key={item.id}><a href={item?.url}>{item.title}</a></li>
                      :
                      <li key={item.id}><Link to={`/page/${item.url}`}>{item.title}</Link></li>
                    ))}
                    </ul>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                  <div className="footer-widget">
                    <h5 className="footer-widget-title">Xller’s Networks</h5>
                    <ul className="footer-widget-menu list-unstyled">
                    {filterByLocation('social').map((item) => (
                      item.type === 'url' ?
                        <li key={item.id}><a href={item?.url} target="_blank">{item.title}</a></li>
                        :
                        <li key={item.id}><Link to={`/page/${item.url}`}>{item.title}</Link></li>
                      ))}
                    </ul>
                  </div>
                </div>
                
              </div>
            </div>
            <div className="copyright-area">
              <div className="row">
                <p className="text-white m-0">{ appearanceData.footer_copyright_text }</p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}