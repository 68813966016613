import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { USER_LOGGED_IN } from "../../Reducer/authSlice";
import { useEffect, useState } from "react";
import API from "../../Network/API";
import { ENROLLMENT_FETCH_START, ENROLLMENT_FETCH_SUCCESS } from "../../Reducer/enrollmentSlice";
import { ENROLLMENT_LESSON_FETCH_START, ENROLLMENT_LESSON_FETCH_SUCCESS } from "../../Reducer/enrollmentLessonSlice";
import { CHAT_LIST_FETCH_SUCCESS } from "../../Reducer/chatListSlice";

export default function StudentNav() {
    const { user, accessToken } = useSelector((state) => state.authReducer);
    const [isLoading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const _logout = () => {
        setLoading(false);
        API.post(`/v1/oauth/logout`)
            .then(res => {
                setLoading(false);
                if (res.data.success === true) {
                    localStorage.clear();
                }
            })
        localStorage.clear();
        window.location.href = '/';
    }

    useEffect(() => {
        dispatch(ENROLLMENT_FETCH_START())
        dispatch(ENROLLMENT_LESSON_FETCH_START())

          API.get(`/v1/oauth/student/my_enrollment_course`).then((response) => {
            dispatch(ENROLLMENT_FETCH_SUCCESS(response.data.data))
            dispatch(ENROLLMENT_LESSON_FETCH_SUCCESS(response.data.data))
          }).catch((error) => {
          });

          API.get(`/v1/oauth/chatlist`).then((response) => {
            dispatch(CHAT_LIST_FETCH_SUCCESS(response.data.data))
          }).catch((error) => {
          });

      }, []);

    return (
        <>
            <div className="stickyPanelSidebar">
                <div className="settings-widget dash-profile">
                    <div className="settings-menu">
                        <div className="profile-bg">
                            <div className="profile-img">
                                <img src={user?.avatar ? user.avatar : '/assets/img/user-default.png' } alt="Img" />
                            </div>
                        </div>
                        <div className="profile-group">
                            <div className="profile-name text-center">
                                <h4><a href="#">{user?.name}</a></h4>
                                <p>{user?.role}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="settings-widget account-settings">
                    <div className="settings-menu">
                        <h3>Dashboard</h3>
                        <ul>
                            <li className="nav-item active">
                                <Link to={`/home/student`} className="nav-link">
                                    <i className="fa-light fa-grid-2" />Dashboard
                                </Link>
                            </li>
                            <li className="nav-item ">
                                <Link to={`/home/chat`} className="nav-link">
                                    <i className="fa-light fa-message-lines" />Messages
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={`/home/my-lessons`} className="nav-link">
                                    <i className="fa-light fa-chalkboard-user" />My lessons
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={`/home/save-course`} className="nav-link">
                                    <i className="fa-light fa-user-graduate" />Saved courses
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={`all-enrollment-courses`} className="nav-link">
                                    <i className="fa-light fa-list" />Enrollment Courses
                                </Link>
                            </li>
                        </ul>
                        <h3>Account Settings</h3>
                        <ul>
                            <li className="nav-item">
                                <Link to={`/home/settings/profile`} className="nav-link ">
                                    <i className="fa-light fa-user-gear" />Settings
                                </Link>
                            </li>
                            <li className="nav-item">
                                <a href="#" className="nav-link ">
                                    <i className="fa-light fa-circle-info" />Help
                                </a>
                            </li>
                            <li className="nav-item">
                            <a href="#" className="nav-link" onClick={() => _logout()}>
                                    <i className="fa-light fa-power-off" />Logout{isLoading ? (
                                        <span
                                            className="spinner-border spinner-border-sm ml-5"
                                            role="status"
                                            aria-hidden="true"
                                        ></span>
                                    ) : (
                                        <span></span>
                                    )}
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}