import { useEffect } from "react"
import Faq from "../components/Faq"
import { Link } from "react-router-dom"

export default function BecomeTeach(){

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

    return (
        <>
        <div className="teacherIntro-area position-relative">
          <div className="container">
            <div className="main-max-width">
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="intro-content-wrap">
                    <div className="intro-heading mb-4">
                      <h2 className="fw-bold">Make a living by teaching the largest community of learners worldwide</h2>
                    </div>
                    <div className="intro-signup-process">
                      <div className="module-icon-box">
                        <div className="row items-center">
                          <div className="col">
                            <div className="iconbox-circle position-relative iconbox-circle-bg-1">
                              <div className="iconbox-icon-wrap">
                                <div className="iconbox-icon-container">
                                  <span className="lqd-icn-ess ">1</span>
                                </div>
                                <div className="iconbox-contents">
                                  <h5 className="iconbox-contents-title">Sign up</h5>
                                  <p className="iconbox-contents-dec">to create your tutor profile</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <div className="iconbox-circle position-relative iconbox-circle-bg-2">
                              <div className="iconbox-icon-wrap">
                                <div className="iconbox-icon-container">
                                  <span className="lqd-icn-ess ">2</span>
                                </div>
                                <div className="iconbox-contents">
                                  <h5 className="iconbox-contents-title">Get approved</h5>
                                  <p className="iconbox-contents-dec">by our team in 5 business days</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <div className="iconbox-circle">
                              <div className="iconbox-icon-wrap">
                                <div className="iconbox-icon-container">
                                  <span className="lqd-icn-ess ">3</span>
                                </div>
                                <div className="iconbox-contents">
                                  <h5 className="iconbox-contents-title">Start earning</h5>
                                  <p className="iconbox-contents-dec">by teaching students all over the world!</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="intro-button mt-4">
                      <Link to={`/signup-tutor`} className="btn rounded becomeTutor-btn input-hight-44 fw-bold">Create a tutor profile now</Link>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="banner-image position-relative">
                    <img className="img-fluid" src="assets/img/tutor-intor-banner.png" alt="shape" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="teacher-future-box">
          <div className="container">
            <div className="row">
              <div className="row">
                <div className="col-lg-4 col-xs-12 col-sm-6">
                  <div className="teacher-future-icon-item">
                    <div className="teacher-future-icon">
                      <img className="img-fluid" src="assets/img/career-03.svg" alt="certified" />
                    </div>
                    <div className="teacher-future-content">
                      <h2>Set your own rate</h2>
                      <p className="mb-0">Choose your hourly rate and change it anytime. On average, English tutors charge $15-25 per hour.</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-xs-12 col-sm-6">
                  <div className="teacher-future-icon-item">
                    <div className="teacher-future-icon">
                      <img className="img-fluid" src="assets/img/career-01.svg" alt="certified" />
                    </div>
                    <div className="teacher-future-content">
                      <h2>Teach anytime, anywhere</h2>
                      <p className="mb-0">Decide when and how many hours you want to teach. No minimum time commitment or fixed schedule. Be your own boss!</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-xs-12 col-sm-6">
                  <div className="teacher-future-icon-item">
                    <div className="teacher-future-icon">
                      <img className="img-fluid" src="assets/img/career-02.svg" alt="certified" />
                    </div>
                    <div className="teacher-future-content">
                      <h2>Grow professionally</h2>
                      <p className="mb-0">Attend professional development webinars and get tips to upgrade your skills. You’ll get all the help you need from our team to grow.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="tech-wrapper">
          <div className="container">
            <div className="learn-anything">
              <div className="row align-items-center">
                <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                  <div className="learn-anything-content">
                    <div className="learn-anything-info">
                      <div className="learn-anything-list-content">
                        <h2 className="title">Teach students from over 180 countries</h2>
                        <p>Xller tutors teach 800,000+ students globally. Join us and you’ll have everything you need to teach successfully.</p>
                      </div>
                    </div>
                    <div className="becomeTutorKeyList learn-anything-list-menu">
                      <ul className="list-unstyled m-0 p-0 becomeTutorList">
                        <li>
                          <div className="becomeTutorList-group">
                            <i className="fa-solid fa-circle" />
                            <p>Steady stream of new students</p>
                          </div>
                        </li>
                        <li>
                          <div className="becomeTutorList-group">
                            <i className="fa-solid fa-circle" />
                            <p>Smart calendar</p>
                          </div>
                        </li>
                        <li>
                          <div className="becomeTutorList-group">
                            <i className="fa-solid fa-circle" />
                            <p>Interactive classroom</p>
                          </div>
                        </li>
                        <li>
                          <div className="becomeTutorList-group">
                            <i className="fa-solid fa-circle" />
                            <p>Convenient payment methods</p>
                          </div>
                        </li>
                        <li>
                          <div className="becomeTutorList-group">
                            <i className="fa-solid fa-circle" />
                            <p>Professional development webinars</p>
                          </div>
                        </li>
                        <li>
                          <div className="becomeTutorList-group">
                            <i className="fa-solid fa-circle" />
                            <p>Supportive tutor community</p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-12 aos-init aos-animate" data-aos="fade-right">
                  <div className="tutorImage">
                    <img className="img-fluid" src="assets/img/woman-with-laptop (1).png" alt="tutor" />
                  </div>
                </div>
              </div>
            </div>
            <div className="learn-anything learn-quote">
              <div className="row align-items-center">
                <div className="col-xl-6 col-lg-6 col-md-6 col-12 aos-init aos-animate" data-aos="fade-right">
                  <div className="tutorImage">
                    <img className="img-fluid" src="assets/img/tutor-2.png" alt="tutor" />
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                  <div className="learn-anything-content learn-quote-content">
                    <div className="learn-anything-info learn-quote-info">
                      <div className="learn-anything-list-content learn-quote-list">
                        <h2>“Xller allowed me to make a living without leaving home!”</h2>
                        <div className="d-flex align-items-center learn-quote-list-teacher-info">
                          <h6 className="m-0">Krista A.</h6>
                          <p className="m-0">・English tutor</p>
                        </div>
                        <a href="#" className="btn rounded becomeTutor-btn">Become a Tutor Now</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        
        <Faq />

        <section className="becomeTutorArea">
          <div className="container">
            <div className="becomeTutorwrapper">
              <div className="row align-items-center">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="tutorImage">
                    <img className="img-fluid" src="assets/img/woman-with-laptop (3).png" alt="tutor" />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="becomeTutorContent">
                    <div className="becomeTutorHeader">
                      <h2>Get paid to teach online</h2>
                    </div>
                    <div className="becomeTutorDec">
                      <p className="mb-0">Connect with thousands of learners around the world and teach from your living room</p>
                    </div>
                    <Link to={`/signup-tutor`} className="btn rounded becomeTutor-btn">Become a Tutor Now</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        </>
    )
}