import AppDownload from "../components/AppDownload";
import Banner from "../components/Banner";
import BecomeTutorArea from "../components/BecomeTutorArea";
import Faq from "../components/Faq";
import GoalSection from "../components/GoalSection";
import HomeCourse from "../components/HomeCourse";
import TestimonialArea from "../components/TestimonialArea";

export default function AppHome(){
    return (
        <>
          <Banner />
            {/* <GoalSection /> */}
            <HomeCourse />
            <BecomeTutorArea />
            <TestimonialArea />
            <Faq />  
        </>
    )
}