import { createSlice } from '@reduxjs/toolkit';

export const teacher_enrollment = createSlice({
    name: 'teacherEnrollmentSlice',
    initialState: {
        loading: false,
        data: [],
        error: false,
        courses: [],
        total_page: 0,
    },
    reducers: {
        TEACHER_ENROLLMENT_FETCH_START: (state) => {
            state.loading = true
        },
        TEACHER_ENROLLMENT_FETCH_SUCCESS: (state, action) => {
            state.loading = false
            state.data = action.payload
            state.courses = action.payload.courses
        },
        TEACHER_ENROLLMENT_FETCH_ERROR: (state) => {
            state.error = true
        }
    }
});

export const { TEACHER_ENROLLMENT_FETCH_ERROR, TEACHER_ENROLLMENT_FETCH_START, TEACHER_ENROLLMENT_FETCH_SUCCESS } = teacher_enrollment.actions;
export default teacher_enrollment.reducer;