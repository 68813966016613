import { createSlice } from '@reduxjs/toolkit';

export const languageSlice = createSlice({
    name:'language',
    initialState:{
        loading: false,
        data:[],
        error: false,
    },
    reducers:{
        LANGUAGE_FETCH_START:(state)=>{
            state.loading=true
        },
        LANGUAGE_FETCH_SUCCESS:(state,action)=>{
            state.loading=false
            state.data=action.payload
            
        },
       
        LANGUAGE_FETCH_ERROR:(state)=>{
            state.error=true
        }
    }
});

export const {LANGUAGE_FETCH_ERROR,LANGUAGE_FETCH_START,LANGUAGE_FETCH_SUCCESS }=languageSlice.actions;
export default languageSlice.reducer;