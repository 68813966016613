import { createSlice } from '@reduxjs/toolkit';

export const enrollmentLessonSlice = createSlice({
    name: 'enrollment_lesson',
    initialState: {
        loading: false,
        data: [],
        error: false,
        courses: [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }],
        total_page: 0,
    },
    reducers: {
        ENROLLMENT_LESSON_FETCH_START: (state) => {
            state.loading = true
        },
        ENROLLMENT_LESSON_FETCH_SUCCESS: (state, action) => {
            state.loading = false
            state.data = action.payload
            state.courses = action.payload.courses
        },
        ENROLLMENT_LESSON_FETCH_ERROR: (state) => {
            state.error = true
        }
    }
});

export const { ENROLLMENT_LESSON_FETCH_ERROR, ENROLLMENT_LESSON_FETCH_START, ENROLLMENT_LESSON_FETCH_SUCCESS } = enrollmentLessonSlice.actions;
export default enrollmentLessonSlice.reducer;