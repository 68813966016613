
import React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SettingNav from "./SettingNav";
import API from "../../Network/API";
import toast from "react-hot-toast";
import { USER_LOGGED_IN } from "../../Reducer/authSlice";

export default function ScheduleSetting() {
    const { user, accessToken } = useSelector((state) => state.authReducer);
    const dispatch = useDispatch();
    const [loading, setLoad] = useState(false);

    const defaultSchedules = {
        Monday: { from_time: '', to_time: '', selected: false },
        Tuesday: { from_time: '', to_time: '', selected: false },
        Wednesday: { from_time: '', to_time: '', selected: false },
        Thursday: { from_time: '', to_time: '', selected: false },
        Friday: { from_time: '', to_time: '', selected: false },
        Saturday: { from_time: '', to_time: '', selected: false },
        Sunday: { from_time: '', to_time: '', selected: false }
    };

    const [schedules, setSchedules] = useState(defaultSchedules);

    // Initialize schedule with user data
    useEffect(() => {
        if (user?.schedules) {
            const updatedSchedules = { ...defaultSchedules };
            user.schedules.forEach(schedule => {
                const day = schedule.day_of_week;
                updatedSchedules[day] = {
                    from_time: schedule.start_time,
                    to_time: schedule.end_time,
                    selected: true
                };
            });
            setSchedules(updatedSchedules);
        }
    }, [user]);

    const handleInputChange = (day, field, value) => {
        setSchedules(prevState => ({
            ...prevState,
            [day]: {
                ...prevState[day],
                [field]: value
            }
        }));
    };

    const handleCheckboxChange = (day) => {
        setSchedules(prevState => ({
            ...prevState,
            [day]: {
                ...prevState[day],
                selected: !prevState[day].selected
            }
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoad(true);
        const selectedSchedules = Object.keys(schedules).filter(day => schedules[day].selected).map(day => ({
            day_of_week: day,
            start_time: schedules[day].from_time,
            end_time: schedules[day].to_time
        }));

        if (selectedSchedules.length === 0) {
            toast.error("Please select at least one schedule.");
            setLoad(false);
            return;
        }

        const payload = {
            user_id: user.id,
            schedules: selectedSchedules
        };

        API.post("/v1/oauth/teacher/settings/schedules",payload )
            .then((response) => {
                setLoad(false);
                toast.success("Schedules updated successfully!");
                localStorage.setItem(
                    'xller_auth',
                    JSON.stringify({
                        user: response?.data?.data?.user,
                        accessToken: localStorage.getItem('xller_token'),
                    })
                )
    
                const userInfo = JSON.parse(localStorage.getItem("xller_auth"));
                if (userInfo) {
                    dispatch(USER_LOGGED_IN(userInfo));
                }
            })
            .catch((err) => {
                setLoad(false);
                toast.error(err.response?.data?.message || "An error occurred");
            });
    };

    return (
        <>
            <div className="settings-widget card-details shadow-sm">
                <div className="settings-menu p-0">
                    <div className="profile-heading">
                        <h3>Settings</h3>
                        <p>You have full control to manage your own account settings</p>
                    </div>
                    <div className="settings-page-head">
                        <SettingNav active_menu={`schedules`} />
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="checkout-form settings-wrap">
                            <div className="edit-profile-info">
                                <h5>Schedules</h5>
                                <p>Edit your schedules information</p>
                            </div>

                            {Object.keys(schedules).map(day => (
                                <div key={day}>
                                    <div className="form-group row">
                                        <div className="col-12">
                                            <div className="form-check custom-form-check mb-3">
                                                <input
                                                    className="form-check-input custom-form-check-input"
                                                    type="checkbox"
                                                    checked={schedules[day].selected}
                                                    onChange={() => handleCheckboxChange(day)}
                                                    id={`flexCheckDefault-${day}`}
                                                />
                                                <label
                                                    className="form-check-label custom-form-check-label"
                                                    htmlFor={`flexCheckDefault-${day}`}
                                                >
                                                    {day}
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    {schedules[day].selected && (
                                        <div className="form-group mb-3 row">
                                            <div className="col-6">
                                                <label className="form-label">From</label>
                                                <input
                                                    type="time"
                                                    className="form-control rounded input-hight-44"
                                                    value={schedules[day].from_time}
                                                    onChange={(e) => handleInputChange(day, 'from_time', e.target.value)}
                                                />
                                            </div>
                                            <div className="col-6">
                                                <label className="form-label">To</label>
                                                <input
                                                    type="time"
                                                    className="form-control rounded input-hight-44"
                                                    value={schedules[day].to_time}
                                                    onChange={(e) => handleInputChange(day, 'to_time', e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ))}

                            <div className="mb-3 d-flex justify-content-end">
                                <button type="submit" className="input-hight-44 btn btn-login box-shadow-1 fw-bold">
                                    Update {loading ? (
                                        <span className="spinner-border spinner-border-sm ml-5" role="status" aria-hidden="true"></span>
                                    ) : (
                                        <span></span>
                                    )}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}
