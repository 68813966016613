import { Link } from "react-router-dom";

export default function StudentSettingNav(props){
    return (
        <>
        <ul className="settings-pg-links list-unstyled">
                        <li><Link to={`/home/settings/profile`} className={`${props.active_menu=='profile'?'active':''}`}>
                            <i className="fa-light fa-edit" />Edit Profile</Link>
                        </li>

                        <li><Link to={`/home/settings/change-password`} className={`${props.active_menu=='password'?'active':''}`}><i className="fa-light fa-lock" />Change Password</Link></li>
                        <li><a href="#"><i className="fa-light fa-bell" />Notifications</a></li>
                    </ul>
        </>
    )
}