export default function GoalSection(){
    return (
        <>
        <section className="goals-section">
        <div className="container">
          <div className="row align-items-center">
            <div className="goals-wapper align-items-center ">
              <div className="goals-count text-center">
                <div className="goals-content ms-0">
                  <h4><span className="counterUp">5000+</span></h4>
                  <p className="mb-0">Experienced  Tutors</p>
                </div>
              </div>
              <div className="goals-count text-center">
                <div className="goals-content ms-0">
                  <h4><span className="counterUp">12000+</span></h4>
                  <p className="mb-0">5- Start Tutor Reviews</p>
                </div>
              </div>
              <div className="goals-count text-center">
                <div className="goals-content ms-0">
                  <h4><span className="counterUp">210+</span></h4>
                  <p className="mb-0">Subjects Taught</p>
                </div>
              </div>
              <div className="goals-count text-center">
                <div className="goals-content ms-0">
                  <h4><span className="counterUp">145+</span></h4>
                  <p className="mb-0">Tutor Nationalities</p>
                </div>
              </div>
              <div className="goals-count text-center">
                <div className="goals-content ms-0">
                  <h4>
                    <span className="counterUp">4.5</span>
                    <span className="counterUpIcon">
                      <i className="fa-light fa-star" />
                      <i className="fa-light fa-star" />
                      <i className="fa-light fa-star" />
                      <i className="fa-light fa-star" />
                    </span>
                  </h4>
                  <p className="mb-0">On The App Store</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
        </>
    )
}