import { createSlice } from '@reduxjs/toolkit';

export const courseSectionSlice = createSlice({
    name: 'courseSection',
    initialState: {
        loading: false,
        data: {},
        main_data: [{id:1},{id:2},{id:3},{id:4}],
        error: false,
        total_page: 0,
    },
    reducers: {
        COURSE_SECTION_FETCH_START: (state) => {
            state.loading = true
        },
        COURSE_SECTION_TOTAL_PAGE: (state,action) => {
            state.total_page = action.payload
        },
        COURSE_SECTION_FETCH_SUCCESS: (state, action) => {
            state.loading = false
            state.data = action.payload
            state.main_data = action.payload.sections
        },
        ADD_COURSE_SECTION_SUCCESS: (state, action) => {
            state.loading = false
            state.main_data = [action.payload, ...state.main_data]
        },
        EDIT_COURSE_SECTION_SUCCESS: (state, action) => {
            state.loading = false
            const newArray = state.main_data.filter(todo => todo.id !== action.payload.id)
            state.main_data = [...newArray, action.payload]
        },
        DELETE_COURSE_SECTION_ROUTE: (state, action) => {
            const filterPosts = state.main_data.filter(
                (post) => post.id !== action.payload
            );
            state.loading = false
            state.main_data = filterPosts
        },
     
        COURSE_SECTION_FETCH_ERROR: (state) => {
            state.error = true
        }
    }
});

export const {ADD_COURSE_SECTION_SUCCESS,EDIT_COURSE_SECTION_SUCCESS,DELETE_COURSE_SECTION_ROUTE, COURSE_SECTION_FETCH_ERROR, COURSE_SECTION_FETCH_START, COURSE_SECTION_FETCH_SUCCESS,COURSE_SECTION_TOTAL_PAGE } = courseSectionSlice.actions;
export default courseSectionSlice.reducer;