import { Link, useLocation } from "react-router-dom";
import API from "../Network/API";
import { useEffect, useState } from "react";
import PostLoader from '../assets/loader.gif';
import moment from "moment";
import toast from "react-hot-toast";
export default function OrderComplete() {
    const location = useLocation();
    const isAppRoute = location.pathname.startsWith("/app");
    const queryParams = new URLSearchParams(location.search);
    const redirect_status = queryParams.get('redirect_status');
    const payment_intent = queryParams.get('payment_intent');
    const data = JSON.parse(localStorage.getItem("data_checkout"));
    const [isLoading, setLoading] = useState(false);
    const [pay_status, setPayStatus] = useState(false)
    const [error_status, setErrorStatus] = useState(false)
    const [errors, setError] = useState()
    const [order, setOrder] = useState()
    useEffect(() => {
        if(!localStorage.getItem('data_checkout')){
            if(isAppRoute){
                window.location.href = '/app';
            }else{
                window.location.href = '/';
            }
            
        }
        if (redirect_status == 'succeeded' && payment_intent != "") {
             submitOrder();
        } else {
            setErrorStatus(true)
        }
    }, [redirect_status]);

    const submitOrder = () => {
        setLoading(true)
        const event = JSON.parse(localStorage.getItem("event"));
        const orderDetails = {
            course_id: data?.course_id,
            lesson_id: data?.lesson_id,
            amount: data?.price,
            teacher_id: data?.teacher_id,
            timezone: data?.timezone,
            payment_intent_id: payment_intent,
            duration: 0,
            start_time: event?.start?moment.tz(event?.start, data?.timezone).format('YYYY-MM-DD hh:mm'):null,
            end_time:  event?.start?moment.tz(event?.end, data?.timezone).format('YYYY-MM-DD hh:mm'):null,
        };
        API.post(`/v1/oauth/order/save`, orderDetails)
            .then(response => {
                setPayStatus(true)
                setLoading(false)
                setOrder(response.data.data)
                setErrorStatus(false)
                
                localStorage.removeItem('data_checkout');
                localStorage.removeItem('event');
                localStorage.removeItem('lesson');
            })
            .catch(error => {
                setLoading(false)
                setPayStatus(false)
                toast.error(error.response.data.message)
            });

    }
    return (
        <>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '80vh',
                margin: 'auto',
                padding: 'auto'
            }}>
                <div className="card" style={{
                    padding: '20px',
                    textAlign: 'center'
                }}>
                    {error_status ?
                        <>
                            <h4>Payment has been unsuccessfully</h4>
                            <p>Sorry! Your purchase has be canceled.Please Try again later</p>
                        </> :
                        <></>
                    }
                    {!pay_status ?
                        <>
                            <h4>Payment processing...</h4>
                            <p>Please wait a moment,while we are <br />Verifying your payment with your bank.The <br />order status will be updated once the payment result is successfully.</p>
                            <p><img src={PostLoader} alt='Post loader' height={80} width={80} /></p>
                        </>
                        :
                        <>
                            <h4 className="text-uppercase" style={{ letterSpacing: '3px' }}>Thank You For Your Purchase</h4>
                            <p>Your Order Number is: <b className="text-dark">{order?.id}</b></p>
                            <p>See your {isAppRoute?<Link to={'/app/home/student'}>Order History</Link>:<Link to={'/home/student'}>Order History</Link>}</p>
                        </>
                    }
                </div>
            </div>
        </>
    )
}