export default function WaitingApproval() {
    return (
        <>
            <div className="text-center " style={{ minHeight: '400px' }}>

                <div className="form-card card mb-4" style={{marginTop:'30px'}}>
                    <div className="card-body">
                        <div className="step-heading">
                            <h2 className="fw-bold">Thank you for completing
                                registration! </h2>
                        </div>
                        <div className="step-dec">
                            <p className="m-0">We've received your application and are currently reviewing it. You will receive an email with the status of your application within 5 business days. </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}