import { Outlet } from "react-router-dom";
import Login from "../../Auth/Login";
import { useDispatch, useSelector } from "react-redux";
import StudentNav from "./StudentNav";
import Breadcrumb from "../../Props/Breadcrumb";

export default function StudentDashboard(){
    const dispatch = useDispatch();
    const { user, accessToken } = useSelector((state) => state.authReducer);
  if (!accessToken) {
    return (
      <>
        <Login />
      </>
    );
  }
    return (
        <>
         <div className="breadcrumb-bar breadcrumb-bar-info">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-12">
            <Breadcrumb title={`Student Dashboard`} sub_title={`Dashboard`}/>
            </div>
          </div>
        </div>
      </div>
      <div className="page-content student-panel">
        <div className="container">
          <div className="row">
            <div className="col-xl-3 col-lg-3 panelSidebar">
              <StudentNav />
            </div>
            <div className="col-lg-9 col-xl-9 col-12">
             
            <Outlet/>

            </div>
          </div>
        </div>
      </div>
        </>
    )
}