import API from "../Network/API"
import toast from 'react-hot-toast';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from "react-router-dom";
export default function SignUp() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [name, setName] = useState("");
  const [gender, setGender] = useState("");
  const dispatch = useDispatch();
  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    if (typeof email !== "undefined") {
      if (!email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/)) {
        toast.error('Email is not correct!');
        return false;
      }
    }
    var data = {
      'name': name,
      'email': email,
      'password': password,
      'gender_id': gender,
      'type': 1,
    }
    // headers: { 'content-type': 'application/x-www-form-urlencoded' }
    API.post('/v1/oauth/register', data).then(response => {
      setLoading(false);
      if (response.data.success === true) {
        localStorage.setItem(
          'xller_auth',
          JSON.stringify({
            user: response?.data?.data?.user,
            accessToken: response?.data?.data?.access_token,
          })
        )

        localStorage.setItem("xller_token", response?.data?.data?.access_token)
        //  console.log(localStorage.getItem('xller_auth'))
        if (response?.data?.data?.user?.role == "teacher") {
          window.location.href = '/dashboard/tutor';
        } else if (response?.data?.data?.user?.role == "student") {
          window.location.href = '/home/student';
        } else {
          window.location.href = '/';
        }

        toast.success(response.message, { duration: 3000 })
      } else {

      }
    }).catch((error) => {
      setLoading(false);
      if (error.response && error.response.data && error.response.data.errors) {
        setErrors(error.response.data.errors);
      } else {
        // console.log("failed");
        // console.log(error.response?.data?.data);
      }

    });

  }

  return (
    <>
      <div className="sign-in-section">
        <div className="container">
          <div className="main-max-width d-flex justify-content-center mxw-400px">
            <div className="sign-content">
              <div className="box-content mb-4">
                <h4>Sign up as a Teacher</h4>
                <div className="singup-link ">
                  <span className="text-dark fw-bold">Already have an account? </span>
                  <span>
                    <Link to={`/login`} className="text-decoration-underline text-dark">Login</Link>
                  </span>
                </div>
              </div>
              <div className="acount-s mb-20 d-none">
                <a href="#" className="btn rounded-pill w-100 mb-2 input-hight-44">
                  <img src="assets/img/google.svg" alt="image" /> Sign in with Google
                </a>
                <a href="#" className="btn rounded-pill w-100 mb-2 input-hight-44">
                  <img src="assets/img/facebook-3-logo-svgrepo-com.svg" alt="image" /> Sign in with Facebook
                </a>
                <a href="#" className="btn rounded-pill w-100 mb-2 input-hight-44">
                  <img src="assets/img/apple-logo-svgrepo-com.svg" alt="image" /> Continue with Apple
                </a>
              </div>
              <div className="loginDivider d-none">
                <p className="loginDivider-text mb-0">or</p>
              </div>
              <div className="log-from mb-30">
                <form onSubmit={handleSubmit}>
                  <div className="form-group mb-2 ">
                    <label htmlFor="name" className="form-label label-style ">Name</label>
                    <input type="text" required onChange={(e) => setName(e.target.value)} className="form-control rounded-pill input-hight-44" id="name" placeholder="Your Name" />
                    {errors.name && <div className="text-danger">{errors.name[0]}</div>}
                  </div>
                  <div className="form-group mb-2">
                    <label htmlFor="exampleInputEmail1" className="form-label ">Gender</label>
                    <select className="form-select" required onChange={(e) => setGender(e.target.value)}>
                      <option value={``}>Select Gender</option>
                      <option value={1}>Male</option>
                      <option value={2}>Female</option>
                    </select>
                    {errors.gender_id && <div className="text-danger">{errors.gender_id[0]}</div>}
                  </div>
                  <div className="form-group mb-2">
                    <label htmlFor="exampleInputEmail1" className="form-label ">Email</label>
                    <input required onChange={(e) => setEmail(e.target.value)} type="email" className="form-control rounded-pill input-hight-44" id="exampleInputEmail1" placeholder="Your Email" />
                    {errors.email && <div className="text-danger">{errors.email[0]}</div>}
                  </div>
                  <div className="form-group mb-1">
                    <label htmlFor="exampleInputPassword1" className="form-label">Password</label>
                    <input required onChange={(e) => setPassword(e.target.value)} type="password" className="form-control input-hight-44 rounded-pill" id="exampleInputPassword1" placeholder="Your Password" />
                    {errors.password && <div className="text-danger">{errors.password[0]}</div>}
                  </div>

                  <button type="submit" className="input-hight-44 btn btn-login w-100 box-shadow-1 rounded-pill fw-bold">Sign up {isLoading ? (
                    <span
                      className="spinner-border spinner-border-sm ml-5"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    <span></span>
                  )}</button>
                </form>
              </div>
              <div className="sign-link text-center">
                <span>By clicking Log in or Continue with, you agree to Xller <a href="#" className="main-colo">Terms of Use</a> and <a href="#" className="main-colo">Privacy Policy</a></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}