import { createSlice } from '@reduxjs/toolkit';

export const categorySlice = createSlice({
    name: 'category',
    initialState: {
        loading: false,
        data: [],
        error: false,
    },
    reducers: {
        CATEGORY_FETCH_START: (state) => {
            state.loading = true
        },
        CATEGORY_FETCH_SUCCESS: (state, action) => {
            state.loading = false
            state.data = action.payload

        },
        CATEGORY_FETCH_ERROR: (state) => {
            state.error = true
        }
    }
});

export const { CATEGORY_FETCH_ERROR, CATEGORY_FETCH_START, CATEGORY_FETCH_SUCCESS } = categorySlice.actions;
export default categorySlice.reducer;