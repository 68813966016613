import { createSlice } from '@reduxjs/toolkit';

export const enrollmentSlice = createSlice({
    name: 'enrollment',
    initialState: {
        loading: false,
        data: [],
        error: false,
        courses: [{id:1},{id:2},{id:3},{id:4}],
        total_page: 0,
    },
    reducers: {
        ENROLLMENT_FETCH_START: (state) => {
            state.loading = true
        },
        ENROLLMENT_FETCH_SUCCESS: (state, action) => {
            state.loading = false
            state.data = action.payload
            state.courses = action.payload.courses
        },
        ENROLLMENT_FETCH_ERROR: (state) => {
            state.error = true
        }
    }
});

export const { ENROLLMENT_FETCH_ERROR, ENROLLMENT_FETCH_START, ENROLLMENT_FETCH_SUCCESS } = enrollmentSlice.actions;
export default enrollmentSlice.reducer;