
import { useDispatch, useSelector } from "react-redux";
import CourseTableRow from "../../Props/CourseTableRow";
import API from "../../Network/API";
import { TEACHER_ENROLLMENT_FETCH_START, TEACHER_ENROLLMENT_FETCH_SUCCESS } from "../../Reducer/tutorEnrollmentSlice";
import { useEffect } from "react";
export default function AllEnrollmentCourse() {
    const enrollment_course = useSelector((state) => state.teacherEnrollmentReducer);
    const dispatch = useDispatch();
    useEffect(() => {

        TEACHER_ENROLLMENT_FETCH_START();
        API.get(`/v1/oauth/teacher/enrollment_courses`).then((response) => {
            dispatch(TEACHER_ENROLLMENT_FETCH_SUCCESS(response.data.data))
        }).catch((error) => {
        });

    }, []);
    return (
        <>

            <div className="dashboard-title">
                <h4>All Enrollment Courses</h4>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="table-responsive custom-table">
                        <table className="table table-nowrap mb-0">
                            <thead>
                                <tr>
                                    <th>Type</th>
                                    <th>Title</th>
                                    <th>Lesson Name</th>
                                    <th>Start Time</th>
                                    <th>Remaining</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {enrollment_course?.loading ?
                                    <>
                                        <tr>
                                            <td colSpan={5} className="text-center fw-bold">Loading.....</td>
                                        </tr>
                                    </>
                                    :

                                    <>
                                        {!enrollment_course?.loading && enrollment_course?.courses?.length > 0 && enrollment_course?.courses?.map((course, j) => (
                                            <CourseTableRow data={course} key={j} />
                                        ))}
                                    </>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}