import { useRef, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import API from '../../../Network/API';
import { ADD_COURSE_SECTION_SUCCESS } from '../../../Reducer/courseSectionSlice';

export default function AddSection({show,onHide,course_id}){
    const [name, setName] = useState("");
    const [isLoading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const inpRef = useRef()
    const modalRef1 = useRef()

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        const fd = new FormData();
        fd.append('title', event.target.title.value)
        fd.append('course_id', course_id)
        API.post("/v1/oauth/teacher/sections/save", fd, {
        }).then((response) => {
            setLoading(false)
            dispatch(ADD_COURSE_SECTION_SUCCESS(response.data.data))
            document.getElementById("add_country_form").reset()
            modalRef1.current.click();
        }).catch((err) => {
            setLoading(false)
        });
      
    }

    return (
        <>
         <Modal show={show} onHide={onHide} keyboard="false"
 backdrop="static">
                <Modal.Header>
                    <h4 class="modal-title">Add Section</h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" ref={modalRef1} onClick={onHide}></button>

                </Modal.Header>

                <Modal.Body>
                    <form onSubmit={handleSubmit} id="add_country_form">
                        <div className="row">
                            <div className="col-sm-12 mb-3">
                                <label htmlFor="name" className="form-label c-label">Name</label>
                                <input required ref={inpRef} name='title' type="text" className="form-control rounded-pill" id="name" placeholder required />
                            </div>
                        </div>

                        <div class="modal-footer">
                            <button type="submit" class="btn btn-success">Submit {isLoading ? (
                                <span
                                    className="spinner-border spinner-border-sm ml-5"
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                            ) : (
                                <span></span>
                            )}</button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    )
}