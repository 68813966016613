
export default function AppDownload() {
    return (
        <section className="newsletter__area">
            <div className="container">
                <div className="row align-items-center g-md-4 g-lg-5 g-sm-2">
                <div className="col-md-7">
                    <div className="colToActionArea position-relative z-3">
                    <div className="title">
                        <h2 className="text-white">
                        Xller is available on Android and IOS
                        </h2>
                    </div>
                    <div className="app-download">
                        <a href="#">
                        <img
                            className="rounded img-fluid"
                            src="assets/img/google-play.svg"
                            alt="img"
                        />
                        </a>
                        <a href="#">
                        <img
                            className="rounded img-fluid"
                            src="assets/img/apple-store.svg"
                            alt="img"
                        />
                        </a>
                    </div>
                    </div>
                </div>
                <div className="col-md-5">
                    <div className="newsletter__img-wrap z-3">
                    <img src="assets/img/newsletter_img.png" className="img-fluid" alt="img" />
                    </div>
                </div>
                </div>
            </div>
            </section>

    )
}