import { useSelector } from "react-redux";
import StudentNav from "./StudentNav";
import { Link } from "react-router-dom";
import CoursePlaceholder from "../../CustomHooks/CoursePlaceholder";

export default function StudentHome() {
  const select_course = useSelector((state) => state.enrollmentReducer);
  const totalEnrollment = select_course?.courses?.reduce((sum, course) => sum + 1, 0);
  return (
    <>
       <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-6">
                  <div className="shadow-sm dashboard__counter-item d-flex align-items-center text-start overflow-hidden position-relative">
                    <div className="icon d-flex justify-content-center align-items-center me-3">
                      <i className="fa-light fa-users" />
                    </div>
                    <div className="content">
                      <span className="count">{totalEnrollment??0}</span>
                      <p className="mb-0">ENROLLED COURSES</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6">
                  <div className="shadow-sm dashboard__counter-item d-flex align-items-center text-start overflow-hidden position-relative">
                    <div className="icon d-flex justify-content-center align-items-center me-3">
                      <i className="fa-light fa-book" />
                    </div>
                    <div className="content">
                      <span className="count">0</span>
                      <p className="mb-0">ACTIVE COURSES</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6">
                  <div className="shadow-sm dashboard__counter-item d-flex align-items-center text-start overflow-hidden position-relative">
                    <div className="icon d-flex justify-content-center align-items-center me-3">
                      <i className="fa-light fa-dollar" />
                    </div>
                    <div className="content">
                      <span className="count">0</span>
                      <p className="mb-0">COMPLETED COURSES</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="dashboard-title">
                <h4>Recently Enrolled Courses</h4>
              </div>
              <div className="row">
                <div className="courses-class-content">
                  <div className="row">
                  {select_course?.loading && select_course?.courses?.map(course => (
                  <CoursePlaceholder />
                ))}
                  {!select_course?.loading && select_course?.courses?.length > 0 && select_course?.courses?.map((row, j) => (
                    <div className="col-lg-4 col-sm-6" key={j}>


                    <div className="single-course mb-2">
                      <div className="courses-image position-relative">
                        <Link to={`/lesson/${row?.course?.slug}/${row?.course?.trial_lessons?.id}`}>
                        <div class="courseMainImg h-200"  style={{backgroundImage: `url(${row?.course?.cover_image == null ? '/assets/img/no_image.jpg' : row?.course?.cover_image})`}}></div>
                        {row?.course?.course_type != 'recorded' ?
                            <span className="single-course-level position-absolute">
                                <a href="#" class="text-white"><i class="fa-solid fa-signal-stream me-1"></i>LIVE</a>
                            </span>
                            : ''
                        }
                        </Link>
                      </div>
                      <div className="courses-content">
                        <div className="CourseAuthorInfo">
                          <a className="authorName" href="#">{row?.course?.teacher?.name}</a>
                          <p className="authorDeg m-0">Teacher</p>
                        </div>
                        <h3 className="title">
                          <Link to={`/lesson/${row?.course?.slug}/${row?.course?.trial_lessons?.id}`}>{row?.course?.title?.substring(0, 30)}</Link>
                        </h3>
                        <p className="course-dec">{row?.course?.description}</p>
                        <div className="course-rating d-flex align-items-center mb-2">
                          <div className="course-rating-icon me-2">
                            <i className={`fas fa-star ${row?.course?.avg_rating >= 0 && row?.course?.avg_rating <= 5 ? 'text-warning' : 'text-dark'}`} />
                            <i className={`fas fa-star ${row?.course?.avg_rating >= 2 && row?.course?.avg_rating <= 5 ? 'text-warning' : 'text-dark'}`} />
                            <i className={`fas fa-star ${row?.course?.avg_rating >= 3 && row?.course?.avg_rating <= 5 ? 'text-warning' : 'text-dark'}`} />
                            <i className={`fas fa-star ${row?.course?.avg_rating >= 4 && row?.course?.avg_rating <= 5 ? 'text-warning' : 'text-dark'}`} />
                            <i className={`fas fa-star ${row?.course?.avg_rating >= 5 && row?.course?.avg_rating <= 5 ? 'text-warning' : 'text-dark'}`} />
                          </div>
                          <span className="course-rating-number">{row?.course?.avg_rating}</span>
                        </div>
                        <p className="courseLessonsNumberCount m-0">{row?.course?.lessons_count} Lessons</p>
                        {
                          row?.course?.course_type=='recorded'?
                          <>
                          <Link to={`/lesson/${row?.course?.slug}/${row?.course?.trial_lessons?.id}`} className="courseBuyBtn">Open</Link>
                          </>
                          :
                          <>
                          <Link to={`/room/${row?.course?.slug}/${row?.room_no}`} className="courseBuyBtn">Join</Link>
                          </>
                        }
                        

                      </div>
                    </div>

                  </div>
               ))}
                  </div>
                </div>
              </div>
    </>
  )
}