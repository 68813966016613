import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import API from "../Network/API";

export default function SinglePage(){
    const prams = useParams();
    const [isLoading, setLoading] = useState(false);
    const [page, setPage] = useState();

    useEffect(() => {
        setLoading(true)
        API.get(`/pages/${prams.slug}`).then((response) => {
            setPage(response.data.data)
           
        }).catch((error) => {
            setLoading(false)
        }).finally (
          setLoading(false),
          window.scrollTo(0, 0)
          );
    }, [prams.slug]);

    return(
        <>
       <div className="breadcrumb-bar breadcrumb-bar-info">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-12">
                <div className="breadcrumb-list">
                  <h2 className="breadcrumb-title">{page?.title}</h2>
                  <nav aria-label="breadcrumb" className="page-breadcrumb">
                    <ol className="breadcrumb">
                      {/* <li className="breadcrumb-item"><a href="/">Home</a></li> */}
                      {/* <li className="breadcrumb-item active" aria-current="page">{page?.title}</li> */}
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page-content">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="single-page">
                  <div className="single-page-image text-center">
                      <img className="img-responsive" src={page?.image} alt={page?.title} style={{height:'300px'}} />
                  </div>
                  <div className="single-page-content">
                    <div dangerouslySetInnerHTML={{ __html: page?.details }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </>
    )
}