import AppDownload from "../components/AppDownload";
import Banner from "../components/Banner";
import BecomeTutorArea from "../components/BecomeTutorArea";
import Faq from "../components/Faq";
import Footer from "../components/Footer";
import GoalSection from "../components/GoalSection";
import Header from "../components/Header";
import HomeCourse from "../components/HomeCourse";
import TestimonialArea from "../components/TestimonialArea";

export default function Home() {

    return (
        <>
           
            <Banner />
            <GoalSection />
            <HomeCourse />
            <BecomeTutorArea />
            <TestimonialArea />
            <Faq />  
            <AppDownload />
        </>
    )

}