import { createSlice } from '@reduxjs/toolkit';

export const appearanceSlice = createSlice({
    name: 'appearance',
    initialState: {
        loading: false,
        data: [],
        error: false,
    },
    reducers: {
        APPEARANCE_FETCH_START: (state) => {
            state.loading = true
        },
        APPEARANCE_FETCH_SUCCESS: (state, action) => {
            state.loading = false
            state.data = action.payload

        },
        APPEARANCE_FETCH_ERROR: (state) => {
            state.error = true
        }
    }
});

export const { APPEARANCE_FETCH_ERROR, APPEARANCE_FETCH_START, APPEARANCE_FETCH_SUCCESS } = appearanceSlice.actions;
export default appearanceSlice.reducer;