import { createSlice } from '@reduxjs/toolkit';

export const UPCOMING_enrollmentSlice = createSlice({
    name: 'upcoming_enrollment',
    initialState: {
        loading: false,
        data: [],
        error: false,
        courses: [],
        total_page: 0,
    },
    reducers: {
        UPCOMING_ENROLLMENT_FETCH_START: (state) => {
            state.loading = true
        },
        UPCOMING_ENROLLMENT_FETCH_SUCCESS: (state, action) => {
            state.loading = false
            state.data = action.payload
            state.courses = action.payload.courses
        },
        UPCOMING_ENROLLMENT_FETCH_ERROR: (state) => {
            state.error = true
        }
    }
});

export const { UPCOMING_ENROLLMENT_FETCH_ERROR, UPCOMING_ENROLLMENT_FETCH_START, UPCOMING_ENROLLMENT_FETCH_SUCCESS } = UPCOMING_enrollmentSlice.actions;
export default UPCOMING_enrollmentSlice.reducer;