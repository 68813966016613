import { useRef, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import API from "../../../Network/API";
import { useNavigate } from "react-router-dom";
import { ADD_MY_COURSE_SUCCESS } from "../../../Reducer/myCourseSlice";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";

export default function AddCourse() {
    const [show, setShow] = useState(false);
    const [course_type, setCourseType] = useState(1);
    const categories = useSelector((state) => state.categoryReducer);
    const [step, setStep] = useState(0);
    const [loading, setLoad] = useState(false);
    const [file, setFile] = useState(null);
    const [video_file, setVideoFile] = useState(null);
    const navigate=useNavigate();
    const dispatch=useDispatch();
    const [photoURL, setPhotoURL] = useState(null);
    const [videoURL, setVideoURL] = useState(null);
    const editorRef = useRef(null);
    const editorRef1 = useRef(null);
    const handleShow = () => {
        setShow(!show)
    };


    const courseType = (type) => {
        setCourseType(type)
    }

    const setSelectedFile = (e) => {
        setFile(e.target.files[0])
        setPhotoURL(URL.createObjectURL(e.target.files[0]))
    };

    const onchangeCover = (e) => {
        setVideoFile(e.target.files[0])
        setVideoURL(URL.createObjectURL(e.target.files[0]))
        // console.log(e.target.files[0]);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoad(true);
       
        if (event.target.title.value == "") {
            toast.error('Course name was required')
            setStep(1)
            return false;
        }

        const editorData = editorRef.current?.editor?.getData();
        const editorData1 = editorRef1.current?.editor?.getData();
        const fd = new FormData();
        fd.append('title', event.target.title.value)
        fd.append('description', editorData)
        fd.append('requirements', editorData1)
        fd.append('category', event.target.category.value)
        fd.append('price', event.target.price.value)
        fd.append('gender_id', event.target.gender.value)
        fd.append('course_type', course_type)
        if (file) {
            fd.append('image', file, file?.name)
        }
        if (video_file) {
            fd.append('video_attach', video_file, video_file?.name)
        }

        API.post("/v1/oauth/teacher/course/save", fd, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },    
            onUploadProgress: progressEvent => {
                 console.log("Progress: " + progressEvent.loaded / progressEvent.total);
            }
        }).then((response) => {
            setLoad(false)
            document.getElementById("msform").reset()
            toast.success(response.data.message);
            dispatch(ADD_MY_COURSE_SUCCESS(response.data.data))
            navigate('/dashboard/my-courses')
            
        }).catch((err) => {
                toast.error(err.response.data.message);
                setLoad(false)
        });
    }

    return (
        <>
            <div className="tutor-sign-in-section newCourseUpload">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-12">
                            <form id="msform" onSubmit={handleSubmit}>

                                <ul id="progressbar" className="newCourseUploadprogressbar">
                                    <li className={`${step == 0 ? 'active' : ''}`} id="type">Course Type</li>
                                    <li className={`${step == 1 ? 'active' : ''}`} id="info">Basic Info</li>
                                    <li className={`${step == 2 ? 'active' : ''}`} id="media">Media</li>
                                    {course_type == 1 ?
                                        <li className={`${step == 3 ? 'active' : ''}`} id="prices">Price</li>
                                        : ''}

                                </ul>

                                <div className="row justify-content-center">
                                    <div className="col-md-12">
                                        <fieldset className={`text-end ${step == 0 ? 'active show' : 'hide'}`} >
                                            <div className="form-card card mb-4">
                                                <div className="card-body">
                                                    <div className="step-heading newCourseUploadStepHeading">
                                                        <h2>First, let's find out what type of course you're making.</h2>
                                                    </div>
                                                    <div className="select-course-type">
                                                        <div className="row">
                                                            <div className="col-xl-4 col-lg-4 col-md-4">
                                                                <div className={`categoryCard ${course_type == 1 ? 'active' : ''}`} onClick={() => courseType(1)}>
                                                                    <div className="categoryCard__icon">
                                                                        <i className="fa-light fa-clapperboard-play" />
                                                                    </div>
                                                                    <div className="categoryCard__content">
                                                                        <h4 className="categoryCard__title">Course</h4>
                                                                        <div className="categoryCard__text">Create rich learning experiences with the help of video lectures, quizzes, coding exercises, etc.</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-4 col-lg-4 col-md-4">
                                                                <div className={`categoryCard ${course_type == 2 ? 'active' : ''}`} onClick={() => courseType(2)}>
                                                                    <div className="categoryCard__icon">
                                                                        <i className="fa-light fa-users-class" />
                                                                    </div>
                                                                    <div className="categoryCard__content">
                                                                        <h4 className="categoryCard__title">Group Class</h4>
                                                                        <div className="categoryCard__text">Create rich learning experiences with the help of video lectures, quizzes, coding exercises, etc.</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-4 col-lg-4 col-md-4">
                                                                <div className={`categoryCard ${course_type == 3 ? 'active' : ''}`} onClick={() => courseType(3)}>
                                                                    <div className="categoryCard__icon">
                                                                        <i className="fa-sharp fa-light fa-list-check" />
                                                                    </div>
                                                                    <div className="categoryCard__content">
                                                                        <h4 className="categoryCard__title">Live Class</h4>
                                                                        <div className="categoryCard__text ">Help students prepare for certification exams by providing practice questions.</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <button onClick={() => setStep(step + 1)} type="button" className="next action-button input-hight-44 btn btn-login box-shadow-1 fw-bold">Next</button>
                                        </fieldset>
                                        <fieldset className={`text-end ${step == 1 ? 'active show' : 'hide'}`}>
                                            <div className="form-card card mb-4">
                                                <div className="card-body">
                                                    <div className="step-heading newCourseUploadStepHeading">
                                                        <h2>Basic Information</h2>
                                                    </div>
                                                    <div className="step-form">
                                                        <div className="form-group mb-3 row">
                                                            <div className="col-12">
                                                                <label htmlFor="Title" className="form-label ">Course Title <span className="ms-1 text-danger">*</span></label>
                                                                <input name="title" type="text" className="form-control rounded input-hight-44" id="Title" placeholder="Learn Figma - UI/UX Design Essential Training" />
                                                            </div>
                                                        </div>
                                                        <div className="form-group mb-3 row">
                                                            <div className="col-6">
                                                                <label className="form-label ">Course Category <span className="ms-1 text-danger">*</span> </label>
                                                                <select name="category" className="form-select input-hight-44" aria-label="Default select">
                                                                    <option>Choose...</option>
                                                                    {categories?.data?.length > 0 && categories?.data?.map((item, i) => (

                                                                        <option value={item.id}>{item.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>

                                                        </div>

                                                        <div className="form-group mb-3 row">
                                                            <div className="col-6">
                                                                <label className="form-label ">For Gender <span className="ms-1 text-danger">*</span> </label>
                                                                <select name="gender" required className="form-select input-hight-44" aria-label="Default select">
                                                                    <option value={3}>Both</option>
                                                                    <option value={1}>Male</option>
                                                                    <option value={2}>Female</option>
                                                                    
                                                                </select>
                                                            </div>

                                                        </div>


                                                        <div className="form-group mb-3 row">
                                                            <div className="col-12">
                                                                <label className="form-label ">Course Description <span className="ms-1 text-danger">*</span></label>
                                                                <CKEditor name="description"
                                                                    editor={ClassicEditor}
                                                                    data={``}
                                                                    onReady={(editor) => {
                                                                        editorRef.current = { editor }; // Store the editor instance in ref
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form-group mb-3 row">
                                                            <div className="col-12">
                                                                <label className="form-label ">Course Requirements <span className="ms-1 text-danger">*</span></label>
                                                                <CKEditor name="requirements"
                                                                    editor={ClassicEditor}
                                                                    data={``}
                                                                    onReady={(editor) => {
                                                                        editorRef1.current = { editor }; // Store the editor instance in ref
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <button onClick={() => setStep(step - 1)} type="button" className="next action-button input-hight-44 btn btn-secondary box-shadow-1 fw-bold me-1">Back</button>
                                            <button onClick={() => setStep(step + 1)} type="button" className="next action-button input-hight-44 btn btn-login box-shadow-1 fw-bold">Next</button>
                                        </fieldset>
                                        <fieldset className={`text-end ${step == 2 ? 'active show' : 'hide'}`}>
                                            <div className="form-card card mb-4">
                                                <div className="card-body">
                                                    <div className="step-heading newCourseUploadStepHeading">
                                                        <h2>Upload Media</h2>
                                                    </div>
                                                    <div className="step-form">
                                                        <div className="form-group mb-3 row">
                                                            <div className="col-6">
                                                                <div style={{ backgroundImage: `url(${photoURL})` }} className="photos-upload-inner newCourseUploadStepPhotos position-relative" id="cvpotos1">
                                                                    <input className="photos-upload-input" type="file" onChange={(e) => setSelectedFile(e)}/>
                                                                    <div className="photos-upload-text">
                                                                        <p className="m-0">Course thumbnail</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-6">
                                                                <div style={{ backgroundImage: `url(${videoURL})` }} className="photos-upload-inner newCourseUploadStepPhotos position-relative" id="cvpotos2">
                                                                    <input className="photos-upload-input" type="file" onChange={(e) => onchangeCover(e)}/>
                                                                    <div className="photos-upload-text">
                                                                        <p className="m-0">Course Sort Video</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <button onClick={() => setStep(step - 1)} type="button" className="next action-button input-hight-44 btn btn-secondary box-shadow-1 fw-bold me-2">Back</button>
                                            {course_type == 1 ?
                                                <button onClick={() => setStep(step + 1)} type="button" className="next action-button input-hight-44 btn btn-login box-shadow-1 fw-bold">Next</button>
                                                :
                                                <button type="submit" className="next action-button input-hight-44 btn btn-login box-shadow-1 fw-bold">Finish{loading ? (
                                                    <span
                                                      className="spinner-border spinner-border-sm ml-5"
                                                      role="status"
                                                      aria-hidden="true"
                                                    ></span>
                                                  ) : (
                                                    <span></span>
                                                  )}</button>

                                            }
                                        </fieldset>
                                        <fieldset className={`text-end ${step == 3 && course_type == 1 ? 'active show' : 'hide'}`}>
                                            <div className="form-card card mb-4">
                                                <div className="card-body">
                                                    <div className="step-heading newCourseUploadStepHeading">
                                                        <h2>Course Price</h2>
                                                    </div>
                                                    <div className="step-form">
                                                        <div className="form-group mb-3 row">
                                                            <div className="col-6">
                                                                <label htmlFor="Price" className="form-label ">Regular Price(USD)<span className="ms-1 text-danger">*</span></label>
                                                                <input name="price" defaultValue={0} type="text" className="form-control rounded input-hight-44" id="Price" placeholder="Regular Price" />
                                                            </div>
                                                           
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <button onClick={() => setStep(step - 1)} type="button" className="next action-button input-hight-44 btn btn-secondary box-shadow-1 fw-bold me-2">Back</button>
                                            <button type="submit" className="next action-button input-hight-44 btn btn-login box-shadow-1 fw-bold">Finish{loading ? (
                    <span
                      className="spinner-border spinner-border-sm ml-5"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    <span></span>
                  )}</button>
                                        </fieldset>
                                    </div>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}