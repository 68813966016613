import { createSlice } from '@reduxjs/toolkit';

export const countrySlice = createSlice({
    name:'country',
    initialState:{
        loading: false,
        data:[],
        error: false,
    },
    reducers:{
        COUNTRY_FETCH_START:(state)=>{
            state.loading=true
        },
        COUNTRY_FETCH_SUCCESS:(state,action)=>{
            state.loading=false
            state.data=action.payload
            
        },
       
        COUNTRY_FETCH_ERROR:(state)=>{
            state.error=true
        }
    }
});

export const {COUNTRY_FETCH_ERROR,COUNTRY_FETCH_START,COUNTRY_FETCH_SUCCESS }=countrySlice.actions;
export default countrySlice.reducer;