import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "./services/i18n";
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';
import { Toaster } from 'react-hot-toast';
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  
  <React.Suspense fallback="Loading...">
  <BrowserRouter>
  <Provider store={store}>
  <Toaster />
    <App />
    </Provider>
    </BrowserRouter>
    </React.Suspense>

);

reportWebVitals();
