import { useEffect, useState } from "react";
import AddSection from "./AddSection";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { COURSE_SECTION_FETCH_START, COURSE_SECTION_FETCH_SUCCESS, COURSE_SECTION_TOTAL_PAGE, DELETE_COURSE_SECTION_ROUTE, EDIT_COURSE_SECTION_SUCCESS } from "../../../Reducer/courseSectionSlice";
import API from "../../../Network/API";
import EditSection from "./EditSection";
import AddLesson from "../Lesson/AddLesson";
import EditLesson from "../Lesson/EditLesson";

export default function CourseSection() {
  const select_sections = useSelector((state) => state.courseSectionReducer);
  const select_course = useSelector((state) => state.myCourseReducer);
  const [course_id, setCourseId] = useState(null);
  const [show, setShow] = useState(false);
  const [show_edit, setShowEdit] = useState(false);
  const prams = useParams();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [section_id, setSectionId] = useState(0);
  const [row_data, setRowData] = useState(null);
  const [course, setData] = useState(null);
  const [show_lesson, setShowLesson] = useState(false);
  const [show_edit_lesson, setShowLessonEdit] = useState(false);
  const [lesson_data, setLessonData] = useState(null);
  const [error, setError] = useState(null);
  const handleShow = (row) => {
    setShow(!show)
    setCourseId(row)
  };

  const handleShowEdit = (row) => {
    setShowEdit(!show_edit)
    setCourseId(prams?.id)
    setRowData(row)
  };

  const handleShowLesson = (row) => {
    setShowLesson(!show_lesson)
    setCourseId(prams?.id)
    setRowData(row)
  };

  const handleShowEditLesson = (row, section) => {
    setShowLessonEdit(!show_edit_lesson)
    setCourseId(prams?.id)
    setRowData(section)
    setLessonData(row)
  };


  useEffect(() => {
    setData(select_course?.courses?.find(course => course.id == prams.id))
    dispatch(COURSE_SECTION_FETCH_START())
    API.get(`/v1/oauth/teacher/sections/${prams?.id}`).then((response) => {
      dispatch(COURSE_SECTION_FETCH_SUCCESS(response.data.data))
      dispatch(COURSE_SECTION_TOTAL_PAGE(response.data.data.total_page))
    }).catch((error) => {
    });
  }, [prams?.id]);

  const delete_section = (id) => {
    setSectionId(id)
    setLoading(true)
    if (window.confirm("Are you sure want to delete this?")) {
      API.delete('/v1/oauth/teacher/sections/' + id + '').then((response) => {
        setLoading(false)
        dispatch(DELETE_COURSE_SECTION_ROUTE(id))
      }).catch((error) => {
        setLoading(false)
        setError(error.response?.data?.message || 'Something went wrong');
      });
    }

  }

  const delete_lesson = (row) => {
    setLessonData(row)
    setLoading(true)
    if (window.confirm("Are you sure want to delete this?")) {
      API.delete('/v1/oauth/teacher/lesson/' + row?.id + '').then((response) => {
        setLoading(false)
        dispatch(EDIT_COURSE_SECTION_SUCCESS(response?.data.data))
      }).catch((error) => {
        setLoading(false)
        setError(error.response?.data?.message || 'Something went wrong');
      });
    }

  }

  return (
    <>
      <div className="AddlessonWrapperHeading mb-3">
        <h2>Lesson</h2>
      </div>
      <div className="AddlessonWrapper card">
        <div>
          <a onClick={() => handleShow(prams?.id)} href className="btn btn-sm btn-outline-dark mt-2 ms-3">
            <i className="fa-light fa-plus" />
            Add Section
          </a>
        </div>
        <div className="card-body">
          <div className="Addlessoninner">

            {!select_sections?.loading && select_sections?.main_data?.length > 0 && select_sections?.main_data?.slice()
              .sort((a, b) => a.serial_no - b.serial_no).map((section, j) => (

                <div className="singleLesson mb-3" key={j}>
                  <div className="singleLessonWrapper bg-light p-4 rounded">
                    <div className="singleLessonHeading fs-16-400 mb-3">
                      <span className="fw-semibold me-1">Section:{j}</span>
                      <span className="me-1 text-muted"><i className="fa-light fa-file" /></span>
                      <span className="text-muted">{section?.name}</span>
                    </div>
                    <div className="singleLessonAddContent ">
                      <div className="bg-white p-2 border rounded">
                        {section?.lessons?.slice()
                          .sort((a, b) => a.serial_no - b.serial_no).map((lesson, k) => (
                            <div className="d-flex justify-content-between align-items-center mb-2">
                              <div className="LessonAddContentleft text-muted">
                                <span className="me-1"><i className="fa-solid fa-check-circle me-1 text-dark" />Lecture:{++k}</span>
                                <span className="me-1"><i className="fa-light fa-file" /></span>
                                <span className>{lesson.title}</span>
                              </div>
                              <div className="LessonAddContentRight">
                                <a onClick={() => handleShowEditLesson(lesson, section)} href className="btn btn-sm btn-outline-dark">
                                  <i className="fa-light fa-pencil" />
                                </a>
                                <a onClick={() => delete_lesson(lesson)} href className="btn btn-sm btn-outline-danger ms-2">
                                  <i className="fa-light fa-trash" />
                                  {lesson_data?.id == lesson?.id && isLoading ? (
                                    <span
                                      className="spinner-border spinner-border-sm ml-5"
                                      role="status"
                                      aria-hidden="true"
                                    ></span>
                                  ) : (
                                    <span></span>
                                  )}
                                </a>
                              </div>
                            </div>
                          ))}

                      </div>
                      <a onClick={() => handleShowLesson(section)} href className="btn btn-sm btn-outline-dark mt-2">
                        <i className="fa-light fa-plus me-2" />
                        Add Lesson
                      </a>

                      <a onClick={() => handleShowEdit(section)} href className="btn btn-sm btn-outline-success mt-2 ms-2">
                        <i className="fa-light fa-plus me-2" />
                        Edit Section
                      </a>

                      <a onClick={() => delete_section(section?.id)} href className="btn btn-sm btn-outline-danger mt-2 ms-2">
                        <i className="fa-light fa-remove me-2" />
                        Delete Section {section_id == section?.id && isLoading ? (
                          <span
                            className="spinner-border spinner-border-sm ml-5"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          <span></span>
                        )}
                      </a>

                    </div>
                  </div>

                </div>
              ))}

          </div>
        </div>
      </div>
      <AddSection show={show} onHide={handleShow} course_id={course_id} />
      <EditSection show={show_edit} onHide={handleShowEdit} course_id={course_id} data={row_data} />

      <AddLesson course={course} show={show_lesson} onHide={handleShowLesson} data={row_data} />
      <EditLesson course={course} show={show_edit_lesson} onHide={handleShowEditLesson} data={row_data} lesson={lesson_data} />
    </>
  )
}