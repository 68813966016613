import { createSlice } from '@reduxjs/toolkit';

export const chatListSlice = createSlice({
    name: 'chatList',
    initialState: {
        loading: false,
        data: [],
        error: false,
    },
    reducers: {
        CHAT_LIST_FETCH_START: (state) => {
            state.loading = true
        },
        CHAT_LIST_FETCH_SUCCESS: (state, action) => {
            state.loading = false
            state.data = action.payload

        },
        CHAT_LIST_FETCH_ERROR: (state) => {
            state.error = true
        }
    }
});

export const { CHAT_LIST_FETCH_ERROR, CHAT_LIST_FETCH_START, CHAT_LIST_FETCH_SUCCESS } = chatListSlice.actions;
export default chatListSlice.reducer;