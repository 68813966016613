import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { USER_LOGGED_IN } from "../../Reducer/authSlice";
import Login from "../../Auth/Login";
import TeacherInfo from "./TeacherInfo";
import WaitingApproval from "./WaitingApproval";
import TutorNav from "./TutorNav";

export default function TutorDashboard(){
    const { user, accessToken } = useSelector((state) => state.authReducer);
 
     // !user ||
  if (!accessToken) {
    return (
      <>
        <Login />
      </>
    );
  }

  if(user.approved==0 && user.teacher_info==''){
    return (
      <>
        <TeacherInfo />
      </>
    );
  }

  if(user.approved==0 && user.teacher_info!=''){
    return (
      <>
        <WaitingApproval />
      </>
    );
  }

    return (
        <>
        <div className="breadcrumb-bar breadcrumb-bar-info">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-12">
                <div className="breadcrumb-list">
                  <h2 className="breadcrumb-title">Tutor Dashboard</h2>
                  <nav aria-label="breadcrumb" className="page-breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item"><a href="#">Home</a></li>
                      <li className="breadcrumb-item active" aria-current="page">Dashboard</li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page-content student-panel">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-3 panelSidebar">
              <TutorNav/>
              </div>
              <div className="col-lg-9 col-xl-9 col-12">
              <Outlet/>
              </div>
            </div>
          </div>
        </div>
        </>
    )
}