import { loadStripe } from '@stripe/stripe-js';
import { useSelector } from "react-redux";
import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';
import { useEffect, useState } from 'react';
import API from '../Network/API';
import { useLocation } from 'react-router-dom';


export default function CardPayment (props)  {
  const location = useLocation();
  const isAppRoute = location.pathname.startsWith("/app");
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  useEffect(() => {

    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: isAppRoute?process.env.REACT_APP_URL+'/app/order/process':process.env.REACT_APP_URL+'/order/process',
      },
    });

    if (result.error) {
      console.log(result.error.message);
    } else {
      


    }
  };

  return (
    <>
     <div className="row">
    <form onSubmit={handleSubmit}>
      <PaymentElement />

        <p className="textmuted h8 mb-2 text-small-12 pt-3">By pressing the "Confirm payment" button, you agree to 
          <a className="text-decoration-underline textmuted " href="#"> Xller’s Refund and Payment Policy</a>
        </p>
        <p className="textmuted h8 mb-2 text-small-12">It’s safe to pay on Xller. All transactions are protected by SSL encryption.</p>
  
      <button type='submit' className="btn rounded becomeTutor-btn w-100">
        Confirm payment · <span className="fw-bold">${props?.price}</span>
      </button>
    </form>
    </div>
    </>
  )
}

