import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import API from "../../Network/API";
import SettingNav from "./SettingNav";
import { USER_LOGGED_IN } from "../../Reducer/authSlice";
import { default as Select } from "react-select";
export default function LanguageSetting() {
    const { user, accessToken } = useSelector((state) => state.authReducer);
    const select_language = useSelector((state) => state.languageReducer);
    const dispatch = useDispatch();
    const [languages, setNewLanguages] = useState([{ language_id: '', type: '' }]);
    const [language, setLanguage] = useState([]);
    // Initialize the state with existing languages or an empty array
    const [selectedLanguages, setLanguages] = useState(user?.languages || []);
    const [loading, setLoad] = useState(false);

    const langTypeOptions = [
        { value: 'basic', label: 'Basic' },
        { value: 'native', label: 'Native' },
        { value: 'fluent', label: 'Fluent' },
        { value: 'conversational', label: 'Conversational' },
    ];

    // Add a new language field
    const addMoreFields = () => {
        setLanguages([...selectedLanguages, { language_id: '', type: '' }]);
    };

    // Remove a specific language field
    const removeField = (index) => {
        const newLanguages = [...selectedLanguages];
        newLanguages.splice(index, 1);
        setLanguages(newLanguages);
    };

    // Handle form submission
    const handleSubmit = (event) => {
        event.preventDefault();
        setLoad(true);

        const languageData = selectedLanguages.map((lang) => ({
            language_id: lang.language_id,
            type: lang.type
        }));

        // Prepare API payload
        const payload = {
            user_id: user.id,
            languages: languageData
        };

        API.post("/v1/oauth/teacher/settings/languages", payload)
            .then((response) => {
                setLoad(false);
                toast.success("Languages updated successfully!");
                localStorage.setItem(
                    'xller_auth',
                    JSON.stringify({
                        user: response?.data?.data?.user,
                        accessToken: localStorage.getItem('xller_token'),
                    })
                )
    
                const userInfo = JSON.parse(localStorage.getItem("xller_auth"));
                if (userInfo) {
                    dispatch(USER_LOGGED_IN(userInfo));
                }
            })
            .catch((err) => {
                setLoad(false);
                toast.error(err.response.data.message);
            });
    };

    return (
        <div className="settings-widget card-details shadow-sm">
            <div className="settings-menu p-0">
                <div className="profile-heading">
                    <h3>Settings</h3>
                    <p>You have full control to manage your own account settings</p>
                </div>
                <div className="settings-page-head">
                    <SettingNav active_menu={`languages`} />
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="checkout-form settings-wrap">
                        <div className="edit-profile-info">
                            <h5>Languages</h5>
                            <p>Edit your languages information</p>
                        </div>

                        <div className="form-group mb-3 row">

                            {selectedLanguages?.length==0?
                            <>
                           {languages.map((lang, index) => (
                                                                    <div className="form-group mb-2 row" key={index}>
                                                                        <div className="col-12">
                                                                            <div className="input-group">
                                                                                <Select
                                                                                    options={language}
                                                                                    isClearable={true}
                                                                                    defaultValue={language.find(option => option.value === lang.language)} // Set the selected language
                                                                                    onChange={(selectedOption) =>
                                                                                        setLanguages(index, 'language_id', selectedOption ? selectedOption.value : '')
                                                                                    }
                                                                                />
                                                                                <select
                                                                                    name="lang_type[]"
                                                                                    className="form-select"
                                                                                    onChange={(e) => setLanguages(index, 'type', e.target.value)}
                                                                                    value={lang.lang_type} // Use the saved value for language type
                                                                                >
                                                                                    {langTypeOptions.map((option) => (
                                                                                        <option key={option.value} value={option.value}>
                                                                                            {option.label}
                                                                                        </option>
                                                                                    ))}
                                                                                </select>
                                                                                {index === languages.length - 1 && (
                                                                                    <button className="input-group-text" type="button" onClick={addMoreFields}>
                                                                                        <i className="fa-solid fa-plus-circle"></i>
                                                                                    </button>
                                                                                )}
                                                                                {index > 0 && (
                                                                                    <button className="input-group-text" type="button" onClick={() => removeField(index)}>
                                                                                        <i className="fa-solid fa-minus-circle text-danger"></i>
                                                                                    </button>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))}
                            </>
                      :
                           <>
                            {selectedLanguages.map((lang, index) => (
                                <div className="form-group mb-2 row" key={index}>
                                    <div className="col-12">
                                        <div className="input-group">
                                            <select
                                                name={`languages[${index}][language_id]`}
                                                className="form-select"
                                                value={lang.language_id || ''}
                                                onChange={(e) => {
                                                    const newLanguages = [...selectedLanguages];
                                                    // Clone the object and update language_id
                                                    newLanguages[index] = {
                                                        ...newLanguages[index],
                                                        language_id: e.target.value
                                                    };
                                                    setLanguages(newLanguages);
                                                }}
                                            >
                                                <option value="">Select one </option>
                                                {!select_language?.loading && select_language?.data?.map((language) => (
                                                    <option key={language.id} value={language.id}>
                                                        {language.name}
                                                    </option>
                                                ))}
                                            </select>

                                            <select
                                                name={`languages[${index}][type]`}
                                                className="form-select"
                                                value={lang.type || ''}
                                                onChange={(e) => {
                                                    const newLanguages = [...selectedLanguages];
                                                    // Clone the object and update type
                                                    newLanguages[index] = {
                                                        ...newLanguages[index],
                                                        type: e.target.value
                                                    };
                                                    setLanguages(newLanguages);
                                                }}
                                            >
                                                {langTypeOptions.map((option) => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </select>

                                            {index === selectedLanguages.length - 1 && (
                                                <button className="input-group-text" type="button" onClick={addMoreFields}>
                                                    <i className="fa-solid fa-plus-circle"></i>
                                                </button>
                                            )}
                                            {index > 0 && (
                                                <button className="input-group-text" type="button" onClick={() => removeField(index)}>
                                                    <i className="fa-solid fa-minus-circle text-danger"></i>
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))}
                           </>
                        }
                        </div>
                        <div className="mb-3 d-flex justify-content-end">
                            <button type="submit" className="input-hight-44 btn btn-login box-shadow-1 fw-bold">
                                Update {loading ? (
                                    <span className="spinner-border spinner-border-sm ml-5" role="status" aria-hidden="true"></span>
                                ) : (
                                    <span></span>
                                )}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}
