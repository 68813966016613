import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import API from "../Network/API";
import PostLoader from '../assets/loader.gif';
import CourseCard from "../components/CourseCard";
import ScheduleSlot from "../components/ScheduleSlot";
export default function TeacherView() {
  const prams = useParams();
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState();
  useEffect(() => {
    setLoading(true)
    API.get(`/v1/oauth/teacher/${prams.id}/show`).then((response) => {
      setLoading(false)
      setData(response.data.data)
    }).catch((error) => {
      setLoading(false)
    }).finally(
      window.scrollTo(0, 0)
    );
  }, [prams.id]);
  return (
    <>
      {isLoading ?
        <div className='content' style={{ margin: 'auto', padding: 'auto', textAlign: 'center', height: '500px' }}>
          <img src={PostLoader} alt='Post loader' height={80} width={80} />
        </div>
        :
        <div className="courseDetails">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-12 offset-md-2">
                <div className="mainInfoWrapper">
                  <div className="mainInfoWrapperAuthorInfo">
                    <div className="d-flex">
                      <div className="flex-shrink-0">
                        <div className="AvatarWrapper position-relative">
                          <img src={data?.avatar} alt={data?.name} />
                        </div>
                      </div>
                      <div className="flex-grow-1">
                        <div className="Authorinfo">
                          <div className="step-upload-photos-name d-flex  align-items-center">
                            <h4 className="me-2 fs-24-600 m-0">{data?.name}</h4>
                            {/* <img src="assets/img/bd.svg" alt="BD" /> */}
                          </div>
                          <p className="m-0 fs-16-400">{data?.teacher_info?.bio}</p>
                        </div>
                        <div className="step-upload-photos-text d-flex mb-2">
                          <i className="fa-light fa-graduation-cap me-2" />
                          <p className="m-0">{data?.teacher_info?.education_title}</p>
                        </div>
                        <div className="step-upload-photos-text mb-2 d-flex">
                          <i className="fa-light fa-quote-left me-2" />
                          <p className="m-0">Speaks:
                            {data?.languages?.length > 0 && data?.languages?.map((lang, i) => (
                              <>
                                <span className="ms-1">{lang.name} </span><span className="badge bg-success text-uppercase">{lang.type}</span>
                              </>
                            ))}
                          </p>
                        </div>
                        <div className="step-upload-photos-text d-flex">
                          <i className="fa-light fa-user me-2" />
                          <p className="m-0">{data?.lessons_count} lessons</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mainInfoWrapperTab">
                    <div className="courseDetailsInfoWrapperTab">
                      <div className="dashboard__nav-wrap">
                        <ul className="nav nav-tabs" id="courseTab" role="tablist">
                          <li className="nav-item" role="presentation">
                            <button className="nav-link active" id="Specialties" data-bs-toggle="tab" data-bs-target="#Specialties-tab-pane" type="button" role="tab" aria-controls="Specialties-tab-pane" aria-selected="false" tabIndex={-1}>
                              All Lessons
                            </button>
                          </li>
                          <li className="nav-item" role="presentation">
                            <button className="nav-link " id="About" data-bs-toggle="tab" data-bs-target="#About-pane" type="button" role="tab" aria-controls="About-pane" aria-selected="true">
                              About
                            </button>
                          </li>
                          <li className="nav-item" role="presentation">
                            <button className="nav-link" id="Schedule" data-bs-toggle="tab" data-bs-target="#Schedule-tab-pane" type="button" role="tab" aria-controls="Schedule-tab-pane" aria-selected="false" tabIndex={-1}>
                              Schedule
                            </button>
                          </li>
                          <li className="nav-item" role="presentation">
                            <button className="nav-link" id="Reviews" data-bs-toggle="tab" data-bs-target="#Reviews-tab-pane" type="button" role="tab" aria-controls="Reviews-tab-pane" aria-selected="false" tabIndex={-1}>
                              Reviews (0)
                            </button>
                          </li>
                        </ul>
                      </div>
                      <div className="tab-content" id="courseTabContent">
                        <div className="tab-pane fade active show" id="Specialties-tab-pane" role="tabpanel" aria-labelledby="Specialties-tab" tabIndex={0}>
                          <div className="courseDetailsTabSpecialties">
                            <div className="row">
                              {data?.courses?.length > 0 && data?.courses?.map((course, j) => (
                                <div className="col-lg-4 col-sm-6" key={j}>
                                  <CourseCard course={course} />
                                </div>
                              ))}

                            </div>
                          </div>
                          
                        </div>


                        <div className="tab-pane fade" id="Schedule-tab-pane" role="tabpanel" aria-labelledby="Schedule-tab" tabIndex={0}>
                          <div className="courseDetailsTabHeading">
                            <h2 className="fs-24-600 mb-0">Available</h2>
                          </div>
                          {/* <ScheduleSlot schedule={data?.teacher_schedules} /> */}
                        </div>

                        <div className="tab-pane fade" id="About-pane" role="tabpanel" aria-labelledby="About" tabIndex={0}>
                          <div className="courseDetailsTabHeading">
                            <h2 className="fs-24-600 mb-0">About</h2>
                          </div>
                          <div className="courseDetailsTabDec">
                            <p className="fs-16-400">
                              {data?.about}
                            </p>

                          </div>
                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      }
    </>
  )
}