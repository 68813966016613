import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import API from "../../../Network/API";
import { DELETE_MY_COURSE_ROUTE } from "../../../Reducer/myCourseSlice";

export default function TutorCourse() {
  const select_course = useSelector((state) => state.myCourseReducer);
  const [isLoading, setLoading] = useState(false);
  const [course_id, setCourseId] = useState(0);
  const dispatch = useDispatch();
  const delete_course = (id) => {
    setCourseId(id)
    setLoading(true)
    if (window.confirm("Are you sure want to delete this?")) {
      API.delete('/v1/oauth/teacher/course/' + id + '').then((response) => {
        setLoading(false)
        dispatch(DELETE_MY_COURSE_ROUTE(id))
      }).catch((error) => {
        setLoading(false)
      });
    }

  }
  return (
    <>
      <div className="dashboard-title d-flex justify-content-between align-items-center">
        <h4>All Courses</h4>
        <Link to={`/dashboard/my-course/create`} className="btn btn-dark"><i className="fa-light fa-plus-circle me-1" />Course Create</Link>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="table-responsive custom-table">
            <table className="table table-nowrap mb-0">
              <thead>
                <tr>
                  <th className="text-start">Courses</th>
                  <th className="text-center">Enrolled</th>
                  <th className="text-center">Add Lesson</th>
                  <th className="text-center">Status</th>
                  <th className="text-center">Type</th>
                  <th className="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {!select_course?.loading && select_course?.courses?.length > 0 && select_course?.courses?.map((course, j) => (
                  <tr>
                    <td>
                      <div className="table-course-detail">
                        <a href="#" className="course-table-img main-course-img">
                          <span><img src={course.cover_image} alt={course.title} /></span>
                          {course.title}
                        </a>
                      </div>
                    </td>
                    <td className="text-center">{course.total_enrollment}</td>
                    <td className="text-center">
                      <Link to={`/dashboard/my-course/sections/${course.id}`} className="addLessonBtn icon-20">
                        <i className="fa-light fa-plus-circle" />
                      </Link>
                    </td>
                    <td className="text-center">{course.active == 1 ? 'Published' : 'Unpublished'}</td>
                    <td className="text-center">{course.course_type}</td>
                    <td className="text-center">
                      <Link to={`/dashboard/my-course/edit/${course?.id}`} title="Edit" className="table-action-btn"><i className="fa-light fa-pen-to-square" /></Link>
                      <a onClick={() => delete_course(course?.id)} href="#" title="Delete" className="table-action-btn"><i className="fa-light fa-trash-alt" /> {course_id==course?.id && isLoading ? (
                        <span
                          className="spinner-border spinner-border-sm ml-5"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        <span></span>
                      )}</a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}