import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useState } from "react";
import API from "../Network/API";
import { useDispatch, useSelector } from "react-redux";
import { USER_LOGGED_IN } from "../Reducer/authSlice";
import toast from "react-hot-toast";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

export default function SubscribeCard({ priceId }) {
   
    const dispatch = useDispatch();
    const stripe = useStripe();
    const elements = useElements();
    const [error, setError] = useState(null);

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!stripe || !elements) {
            return; // Stripe.js has not loaded yet.
        }

        // Get the card element
        const cardElement = elements.getElement(CardElement);

        // Create the payment method
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
        });

        if (error) {
            setError(error.message);
            return;
        }

        // Pass the paymentMethod.id and priceId to your backend
        const fd = new FormData();
        fd.append('priceId', priceId);
        fd.append('payment_method', paymentMethod.id); // Pass the valid payment method ID

        API.post("/v1/oauth/teacher/subscription", fd, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            toast.success("Languages updated successfully!");
                localStorage.setItem(
                    'xller_auth',
                    JSON.stringify({
                        user: response?.data?.data?.user,
                        accessToken: localStorage.getItem('xller_token'),
                    })
                )
    
                const userInfo = JSON.parse(localStorage.getItem("xller_auth"));
                if (userInfo) {
                    dispatch(USER_LOGGED_IN(userInfo));
                }
        }).catch((err) => {
            console.log(err);
        });
    };

    return (
        <>
            <form onSubmit={handleSubmit}>
                <CardElement />
                {error && <div>{error}</div>}
                <button type="submit" className="w-100 btn btn-lg btn-outline-primary mt-3" disabled={!stripe}>
                    Purchase
                </button>
            </form>
        </>
    );
}
