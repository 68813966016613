import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Modal } from 'react-bootstrap';
import API from "../../../Network/API";
import { EDIT_COURSE_SECTION_SUCCESS } from "../../../Reducer/courseSectionSlice";

export default function AddLesson({ show, onHide, course, data }) {
    const [name, setName] = useState("");
    const [isLoading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const inpRef = useRef()
    const modalRef1 = useRef()
    const [file, setFile] = useState(null);
    const setSelectedFile = (e) => {
        setFile(e.target.files[0])
    };

    const [type, setType] = useState(false);

    const checkUploadType=(event)=>{
        if(event=='file'){
            setType(true);
        }else{
            setType(false);
        }
        
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        const fd = new FormData();
        fd.append('title', event.target.title.value)

        fd.append('description', event.target.description.value)
        if (course?.course_type == 'recorded') {

            if (file) {
                fd.append('attachment', file, file?.name)
            }

            fd.append('lesson_type', 'recorded')
            fd.append('video_url', event.target.video_url.value)
            fd.append('url_type', event.target.url_type.value)
            fd.append('duration', event.target.duration1.value + ':' + event.target.duration2.value + ':' + event.target.duration.value)
        } else {
            fd.append('is_trial', event.target.is_trial.value)
            fd.append('lesson_type', 'live')
            fd.append('lesson_price', event.target.lesson_price.value)

        }

        if (course?.course_type == 'group') {
            fd.append('max_students', event.target.max_students.value)
        } else {
            fd.append('max_students', 1)
        }

      

        fd.append('course_id', course?.id)
        fd.append('section_id', data?.id)

        API.post("/v1/oauth/teacher/lesson/save", fd, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }, 
        }).then((response) => {
            setLoading(false)
            dispatch(EDIT_COURSE_SECTION_SUCCESS(response.data.data))
            document.getElementById("add_country_form").reset()
            modalRef1.current.click();
        }).catch((err) => {
            setLoading(false)
        });

    }

    return (
        <>
            <Modal show={show} onHide={onHide} keyboard="false"
                backdrop="static">
                <Modal.Header>
                    <h4 class="modal-title">Add Lesson</h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" ref={modalRef1} onClick={onHide}></button>

                </Modal.Header>

                <Modal.Body>
                    <form onSubmit={handleSubmit} id="add_country_form">
                        <div className="row">
                            <div className="col-sm-12 mb-2">
                                <label htmlFor="name" className="form-label c-label">Title<em className="text-danger">*</em></label>
                                <input required ref={inpRef} name='title' type="text" className="form-control rounded-1" id="name" placeholder />
                            </div>
                            {course?.course_type != 'recorded' ?
                                <>
                                    <div className="col-sm-12 mb-2">
                                        <label htmlFor="is_trial" className="form-label c-label">Trial Lesson<em className="text-danger">*</em></label>
                                        <select name="is_trial" className="form-select">
                                            <option value={0}>No</option>
                                            <option value={1}>Yes</option>
                                        </select>
                                    </div>
                                    <div className="col-sm-12 mb-2">
                                        <label htmlFor="name" className="form-label c-label">Price<em className="text-danger">*</em></label>
                                        <input defaultValue={0} ref={inpRef} name='lesson_price' type="number" step={0.2} min={0} className="form-control rounded-1" id="name" placeholder />
                                    </div>
                                </>
                                : ''
                            }

                            {course?.course_type == 'group' ?
                                <div className="col-sm-12 mb-2">
                                    <label htmlFor="max_students" className="form-label c-label">Max Students<em className="text-danger">*</em></label>
                                    <input ref={inpRef} name='max_students' type="number" min={0} className="form-control rounded-1" id="name" placeholder />
                                </div>
                                : ''
                            }

                            <div className="col-sm-12 mb-2">
                                <label htmlFor="description" className="form-label c-label">Description<em className="text-danger">*</em></label>
                                <textarea required ref={inpRef} name='description' type="text" className="form-control rounded-1" id="name" placeholder />
                            </div>

                            {course?.course_type == 'recorded' ?
                                <>

                                    <div className="col-sm-12 mb-2">
                                        <label htmlFor="url_type" className="form-label c-label">Upload Type<em className="text-danger">*</em></label>
                                        <select name="url_type" className="form-select" onChange={(e)=>checkUploadType(e.target.value)}>
                                            <option value={`url`}>Url</option>
                                            <option value={`file`}>File</option>
                                        </select>
                                    </div>

                                    <div className={`col-sm-12 mb-2 ${type==false?'d-block':'d-none'}`}>
                                        <label htmlFor="video_url" className="form-label c-label">Video URL<em className="text-danger">*</em></label>
                                        <input ref={inpRef} name='video_url' type="text" className="form-control rounded-1" id="name" placeholder />
                                    </div>

                                    <div className={`col-sm-12 mb-2 ${type==true?'d-block':'d-none'}`}>
                                        <label htmlFor="attachment" className="form-label c-label">Upload File<em className="text-danger">*</em></label>
                                         <input id="attachment" type="file" onChange={(e) => setSelectedFile(e)}/>
                                    </div>


                                    <div className="col-sm-12 mb-2">
                                        <label htmlFor="duration" className="form-label c-label">Video Duration<em className="text-danger">*</em></label>
                                        <div class="input-group">
                                            <span className="input-group-text">Hour</span>
                                            <input defaultValue={0} name="duration1" ref={inpRef} type="number" className="form-control" placeholder="00" min={0} max={24} />
                                            <span className="input-group-text">Minute</span>
                                            <input defaultValue={0} name="duration2" ref={inpRef} type="number" className="form-control" placeholder="00" min={0} max={60} />
                                            <span className="input-group-text">Second</span>
                                            <input defaultValue={0} name="duration" ref={inpRef} type="number" className="form-control" placeholder="00" min={0} max={60} />
                                        </div>
                                    </div>
                                </>
                                : ''
                            }



                        </div>

                        <div class="modal-footer">
                            <button type="submit" class="btn btn-success">Submit {isLoading ? (
                                <span
                                    className="spinner-border spinner-border-sm ml-5"
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                            ) : (
                                <span></span>
                            )}</button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    )
}