import react from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

export default function BecomeTutorArea(){

  const selector = useSelector((state) => state.appearanceReducer );
  const appearanceData = selector?.data;

    return (
        <>
        <section className="becomeTutorArea">
        <div className="container">
          <div className="becomeTutorwrapper">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="tutorImage">
                  <img className="img-fluid" src="assets/img/woman-with-laptop (1).png" alt="tutor" />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="becomeTutorContent">
                  <div className="becomeTutorHeader">
                    <h2>{ appearanceData.tutor_area_header }</h2>
                  </div>
                  <div className="becomeTutorDec">
                    <p className="mb-0">{appearanceData.tutor_area_details}</p>
                  </div>
                  {/* <div className="becomeTutorKeyList">
                    <ul className="list-unstyled m-0 p-0 becomeTutorList">
                      <li>
                        <div className="becomeTutorList-group">
                          <i className="fa-solid fa-circle" />
                          <p>Create a secure and rewarding career.</p>
                        </div>
                      </li>
                      <li>
                        <div className="becomeTutorList-group">
                          <i className="fa-solid fa-circle" />
                          <p>Create a secure and rewarding career.</p>
                        </div>
                      </li>
                      <li>
                        <div className="becomeTutorList-group">
                          <i className="fa-solid fa-circle" />
                          <p>Create a secure and rewarding career.</p>
                        </div>
                      </li>
                      <li>
                        <div className="becomeTutorList-group">
                          <i className="fa-solid fa-circle" />
                          <p>Create a secure and rewarding career.</p>
                        </div>
                      </li>
                      <li>
                        <div className="becomeTutorList-group">
                          <i className="fa-solid fa-circle" />
                          <p>Create a secure and rewarding career.</p>
                        </div>
                      </li>
                    </ul>
                  </div> */}
                  <Link to="/teach" className="btn rounded becomeTutor-btn">Become a Tutor Now</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
        </>
    )
}