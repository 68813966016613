import { createSlice } from '@reduxjs/toolkit';

export const teacherSlice = createSlice({
    name: 'teacher',
    initialState: {
        loading: false,
        data: {},
        teachers: [{id:1},{id:2},{id:3},{id:4}],
        error: false,
        total_page: 0,
    },
    reducers: {
        TEACHER_FETCH_START: (state) => {
            state.loading = true
        },
        TEACHER_TOTAL_PAGE: (state,action) => {
            state.total_page = action.payload
        },
        TEACHER_FETCH_SUCCESS: (state, action) => {
            state.loading = false
            state.data = action.payload
            state.teachers = action.payload.teachers
        },
        TEACHER_FETCH_ERROR: (state) => {
            state.error = true
        }
    }
});

export const { TEACHER_FETCH_ERROR, TEACHER_FETCH_START, TEACHER_FETCH_SUCCESS,TEACHER_TOTAL_PAGE } = teacherSlice.actions;
export default teacherSlice.reducer;