import { createSlice } from '@reduxjs/toolkit';

export const myCourseSlice = createSlice({
    name: 'my_course',
    initialState: {
        loading: false,
        data: {},
        get_data: {},
        courses: [{id:1},{id:2},{id:3},{id:4}],
        error: false,
        total_page: 0,
    },
    reducers: {
        MY_COURSE_FETCH_START: (state) => {
            state.loading = true
        },
        MY_COURSE_TOTAL_PAGE: (state,action) => {
            state.total_page = action.payload
        },
        MY_COURSE_FETCH_SUCCESS: (state, action) => {
            state.loading = false
            state.data = action.payload
            state.courses = action.payload.courses
        },
        ADD_MY_COURSE_SUCCESS: (state, action) => {
            state.loading = false
            state.courses = [action.payload, ...state.courses]
        },
        EDIT_MY_COURSE_SUCCESS: (state, action) => {
            state.loading = false
            const newArray = state.courses.filter(todo => todo.id !== action.payload.id)
            state.courses = [...newArray, action.payload]
        },
        DELETE_MY_COURSE_ROUTE: (state, action) => {
            const filterPosts = state.courses.filter(
                (post) => post.id !== action.payload
            );
            state.loading = false
            state.courses = filterPosts
        },
        FIND_MY_COURSE_FETCH_SUCCESS: (state, action) => {
            state.loading = false
            const get_course = state.courses.find(course => course.id === action.payload.id)
            state.get_data = get_course
        },
        MY_COURSE_FETCH_ERROR: (state) => {
            state.error = true
        }
    }
});

export const {ADD_MY_COURSE_SUCCESS,EDIT_MY_COURSE_SUCCESS,DELETE_MY_COURSE_ROUTE, MY_COURSE_FETCH_ERROR, MY_COURSE_FETCH_START, MY_COURSE_FETCH_SUCCESS,MY_COURSE_TOTAL_PAGE,FIND_MY_COURSE_FETCH_SUCCESS } = myCourseSlice.actions;
export default myCourseSlice.reducer;